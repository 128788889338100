import s from './style.module.scss';
import { createElement, FC } from 'react';
import { MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons';
import { Typography, Layout } from 'antd';
// import { useDispatch } from '../../../store/types';
// import { setIsAuth } from '../../../store/user/user.slice';

type Props = {
  collapsed: boolean;
  setCollapsed: (collapsed: boolean) => void;
};
export const Header: FC<Props> = (props) => {
  const { collapsed, setCollapsed } = props;

  // const dispatch = useDispatch();

  // const logOut = () => {
  //   dispatch(setIsAuth(false));
  // };

  return (
    <Layout.Header className={s.header}>
      {createElement(collapsed ? MenuUnfoldOutlined : MenuFoldOutlined, {
        className: 'trigger',
        onClick: () => setCollapsed(!collapsed),
      })}
      <div className={s.content}>
        <Typography.Title level={3} className={s.title}>
          Клуб лояльности к себе
        </Typography.Title>
        {/*<Button danger type="primary" onClick={logOut}>*/}
        {/*  Выйти*/}
        {/*</Button>*/}
      </div>
    </Layout.Header>
  );
};
