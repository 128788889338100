import { FC, useEffect, useState } from 'react';
import s from './style.module.scss';
import { CardBackground } from '../card-background';
import { TypeCard } from '../../types';

type Props = {
  card: TypeCard;
  onRemove?: (item: TypeCard) => void;
  className?: string;
  openCard: boolean;
  cardNumber: number;
};

export const CardOnBoard: FC<Props> = (props) => {
  const { card, onRemove, className, cardNumber } = props;

  const [scale, setScale] = useState(1);
  const [openCard, setOpenCard] = useState(() => props.openCard);
  const [focusOnCard, setFocusOnCard] = useState(false);

  useEffect(() => {
    const cards = localStorage.getItem('cards');

    if (!cards) {
      return;
    }

    const cardList: TypeCard[] = JSON.parse(cards);
    const currentCard = cardList.find((i) => i.id === card.id);

    if (!currentCard) {
      return;
    }

    const newCardList = cardList.map((i) => {
      if (i.id === card.id) {
        return { ...card, openCard };
      }

      return i;
    });

    localStorage.setItem('cards', JSON.stringify(newCardList));
  }, [openCard]);

  const handleScale = (type: 'minus' | 'plus') => {
    if (type === 'minus') {
      if (scale <= 0.8) {
        return;
      }

      setScale((prevState) => prevState - 0.1);
    }

    if (type === 'plus') {
      if (scale >= 1.5) {
        return;
      }

      setScale((prevState) => prevState + 0.1);
    }
  };

  return (
    <div
      className={className ? `${s.cardContainer} ${className}` : s.cardContainer}
      onBlur={(e) => {
        if (!e.currentTarget.contains(e.relatedTarget)) {
          setFocusOnCard(false);
        }
      }}
      onFocus={() => setFocusOnCard(true)}
      tabIndex={1}
      style={{ zIndex: focusOnCard ? 1 : 0 }}
    >
      {openCard ? (
        <img className={s.cardImage} src={card.url} alt="card" style={{ transform: `scale(${scale})` }} />
      ) : (
        <div className={s.cardBackgroundWrapper}>
          <CardBackground cardNumber={cardNumber} />
        </div>
      )}

      {focusOnCard && (
        <div className={s.buttonWrapper}>
          {onRemove && (
            <button className={s.button} onClick={() => onRemove(card)}>
              x
            </button>
          )}
          <button className={s.button} onClick={() => setOpenCard(!openCard)}>
            ↺
          </button>
          <button className={s.button} onClick={() => handleScale('minus')}>
            -
          </button>
          <button className={s.button} onClick={() => handleScale('plus')}>
            +
          </button>
        </div>
      )}
    </div>
  );
};
