import { createElement, FC, useState } from 'react';
import s from './style.module.scss';
import cn from 'classnames';
import { TypeCard } from '../../types';
import { CardOnList } from '../cards/card-on-list';
import { MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons';

type Props = {
  openCards: boolean;
  setOpenCards: (open: boolean) => void;
  cardList: TypeCard[];
  shuffleCardList: () => void;
  addCardToBoard: (card: TypeCard) => void;
  deck: string;
  clearBoard: () => void;
};

export const CardList: FC<Props> = (props) => {
  const { clearBoard, openCards, setOpenCards, cardList, shuffleCardList, addCardToBoard, deck } = props;

  const [collapsed, setCollapsed] = useState(false);

  return (
    <div className={cn(s.footer, { [s.collapsed]: collapsed })}>
      <button className={s.triggerIcon} onClick={() => setCollapsed(!collapsed)}>
        {createElement(collapsed ? MenuUnfoldOutlined : MenuFoldOutlined, {
          className: 'trigger',
        })}
      </button>
      <div className={s.footerButtons}>
        <button className={s.footerButton} onClick={shuffleCardList}>
          Перемешать
        </button>
        <button className={s.footerButton} onClick={() => setOpenCards(!openCards)}>
          {openCards ? 'Закрыть карты' : 'Открыть карты'}
        </button>
        <button className={s.footerButton} onClick={clearBoard}>
          Очистить стол
        </button>
      </div>
      <div className={s.cardsWrapper}>
        {cardList.map((card) => (
          <CardOnList
            card={card}
            key={card.id + card.url}
            openCards={openCards}
            onClick={() => addCardToBoard(card)}
            deck={deck}
          />
        ))}
      </div>
    </div>
  );
};
