import { FC } from 'react';
import s from './style.module.scss';
import logo from '../../images/main-image.png';

type Props = {
  cardDeckName?: string;
  cardNumber?: number;
};

export const CardBackground: FC<Props> = (props) => {
  const { cardDeckName, cardNumber } = props;

  return (
    <div className={s.cardBackgroundWrapper}>
      <div className={s.cardBackground} />
      <img className={s.logo} src={logo} alt="Логотип" />
      {cardDeckName && <p className={s.deckName}>{cardDeckName}</p>}
      {cardNumber && <p className={s.cardNumber}>{cardNumber}</p>}
    </div>
  );
};
