import image1 from '../images/decks/resource-place/1.jpg';
import image2 from '../images/decks/resource-place/2.jpg';
import image3 from '../images/decks/resource-place/3.jpg';
import image4 from '../images/decks/resource-place/4.jpg';
import image5 from '../images/decks/resource-place/5.jpg';
import image6 from '../images/decks/resource-place/6.jpg';
import image7 from '../images/decks/resource-place/7.jpg';
import image8 from '../images/decks/resource-place/8.jpg';
import image9 from '../images/decks/resource-place/9.jpg';
import image10 from '../images/decks/resource-place/10.jpg';
import image11 from '../images/decks/resource-place/11.jpg';
import image12 from '../images/decks/resource-place/12.jpg';
import image13 from '../images/decks/resource-place/13.jpg';
import image14 from '../images/decks/resource-place/14.jpg';
import image15 from '../images/decks/resource-place/15.jpg';
import image16 from '../images/decks/resource-place/16.jpg';
import image17 from '../images/decks/resource-place/17.jpg';
import image18 from '../images/decks/resource-place/18.jpg';
import image19 from '../images/decks/resource-place/19.jpg';
import image20 from '../images/decks/resource-place/20.jpg';
import image21 from '../images/decks/resource-place/21.jpg';
import image22 from '../images/decks/resource-place/22.jpg';
import image23 from '../images/decks/resource-place/23.jpg';
import image24 from '../images/decks/resource-place/24.jpg';
import image25 from '../images/decks/resource-place/25.jpg';
import image26 from '../images/decks/resource-place/26.jpg';
import image27 from '../images/decks/resource-place/27.jpg';
import image28 from '../images/decks/resource-place/28.jpg';
import image29 from '../images/decks/resource-place/29.jpg';
import image30 from '../images/decks/resource-place/30.jpg';
import image31 from '../images/decks/resource-place/31.jpg';
import image32 from '../images/decks/resource-place/32.jpg';
import image33 from '../images/decks/resource-place/33.jpg';
import image34 from '../images/decks/resource-place/34.jpg';
import image35 from '../images/decks/resource-place/35.jpg';
import image36 from '../images/decks/resource-place/36.jpg';
import image37 from '../images/decks/resource-place/37.jpg';
import image38 from '../images/decks/resource-place/38.jpg';
import image39 from '../images/decks/resource-place/39.jpg';
import image40 from '../images/decks/resource-place/40.jpg';
import image41 from '../images/decks/resource-place/41.jpg';
import image42 from '../images/decks/resource-place/42.jpg';
import image43 from '../images/decks/resource-place/43.jpg';
import image44 from '../images/decks/resource-place/44.jpg';
import image45 from '../images/decks/resource-place/45.jpg';
import image46 from '../images/decks/resource-place/46.jpg';
import image47 from '../images/decks/resource-place/47.jpg';
import image48 from '../images/decks/resource-place/48.jpg';
import image49 from '../images/decks/resource-place/49.jpg';
import image50 from '../images/decks/resource-place/50.jpg';
import image51 from '../images/decks/resource-place/51.jpg';
import image52 from '../images/decks/resource-place/52.jpg';
import image53 from '../images/decks/resource-place/53.jpg';
import image54 from '../images/decks/resource-place/54.jpg';
import image55 from '../images/decks/resource-place/55.jpg';
import image56 from '../images/decks/resource-place/56.jpg';
import image57 from '../images/decks/resource-place/57.jpg';
import image58 from '../images/decks/resource-place/58.jpg';
import image59 from '../images/decks/resource-place/59.jpg';
import image60 from '../images/decks/resource-place/60.jpg';
import image61 from '../images/decks/resource-place/61.jpg';
import image62 from '../images/decks/resource-place/62.jpg';
import image63 from '../images/decks/resource-place/63.jpg';
import image64 from '../images/decks/resource-place/64.jpg';
import image65 from '../images/decks/resource-place/65.jpg';
import image66 from '../images/decks/resource-place/66.jpg';
import image67 from '../images/decks/resource-place/67.jpg';
import image68 from '../images/decks/resource-place/68.jpg';
import image69 from '../images/decks/resource-place/69.jpg';
import image70 from '../images/decks/resource-place/70.jpg';
import image71 from '../images/decks/resource-place/71.jpg';
import image72 from '../images/decks/resource-place/72.jpg';
import image73 from '../images/decks/resource-place/73.jpg';
import image74 from '../images/decks/resource-place/74.jpg';
import image75 from '../images/decks/resource-place/75.jpg';
import image76 from '../images/decks/resource-place/76.jpg';
import image77 from '../images/decks/resource-place/77.jpg';
import image78 from '../images/decks/resource-place/78.jpg';
import image79 from '../images/decks/resource-place/79.jpg';
import image80 from '../images/decks/resource-place/80.jpg';
import image81 from '../images/decks/resource-place/81.jpg';
import image82 from '../images/decks/resource-place/82.jpg';
import image83 from '../images/decks/resource-place/83.jpg';
import image84 from '../images/decks/resource-place/84.jpg';
import image85 from '../images/decks/resource-place/85.jpg';
import image86 from '../images/decks/resource-place/86.jpg';
import image87 from '../images/decks/resource-place/87.jpg';
import image88 from '../images/decks/resource-place/88.jpg';
import image89 from '../images/decks/resource-place/89.jpg';
import image90 from '../images/decks/resource-place/90.jpg';
import image91 from '../images/decks/resource-place/91.jpg';
import image92 from '../images/decks/resource-place/92.jpg';
import image93 from '../images/decks/resource-place/93.jpg';
import image94 from '../images/decks/resource-place/94.jpg';
import image95 from '../images/decks/resource-place/95.jpg';
import image96 from '../images/decks/resource-place/96.jpg';
import image97 from '../images/decks/resource-place/97.jpg';
import image98 from '../images/decks/resource-place/98.jpg';
import image99 from '../images/decks/resource-place/99.jpg';
import image100 from '../images/decks/resource-place/100.jpg';
import image101 from '../images/decks/resource-place/101.jpg';
import image102 from '../images/decks/resource-place/102.jpg';
import image103 from '../images/decks/resource-place/103.jpg';
import image104 from '../images/decks/resource-place/104.jpg';
import image105 from '../images/decks/resource-place/105.jpg';
import image106 from '../images/decks/resource-place/106.jpg';
import image107 from '../images/decks/resource-place/107.jpg';
import image108 from '../images/decks/resource-place/108.jpg';
import image109 from '../images/decks/resource-place/109.jpg';

export const resourcePlace = {
  title: 'Место ресурса',
  cards: [
    { id: 1 + 'resource-place', url: image1 },
    { id: 2 + 'resource-place', url: image2 },
    { id: 3 + 'resource-place', url: image3 },
    { id: 4 + 'resource-place', url: image4 },
    { id: 5 + 'resource-place', url: image5 },
    { id: 6 + 'resource-place', url: image6 },
    { id: 7 + 'resource-place', url: image7 },
    { id: 8 + 'resource-place', url: image8 },
    { id: 9 + 'resource-place', url: image9 },
    { id: 10 + 'resource-place', url: image10 },
    { id: 11 + 'resource-place', url: image11 },
    { id: 12 + 'resource-place', url: image12 },
    { id: 13 + 'resource-place', url: image13 },
    { id: 14 + 'resource-place', url: image14 },
    { id: 15 + 'resource-place', url: image15 },
    { id: 16 + 'resource-place', url: image16 },
    { id: 17 + 'resource-place', url: image17 },
    { id: 18 + 'resource-place', url: image18 },
    { id: 19 + 'resource-place', url: image19 },
    { id: 20 + 'resource-place', url: image20 },
    { id: 21 + 'resource-place', url: image21 },
    { id: 22 + 'resource-place', url: image22 },
    { id: 23 + 'resource-place', url: image23 },
    { id: 24 + 'resource-place', url: image24 },
    { id: 25 + 'resource-place', url: image25 },
    { id: 26 + 'resource-place', url: image26 },
    { id: 27 + 'resource-place', url: image27 },
    { id: 28 + 'resource-place', url: image28 },
    { id: 29 + 'resource-place', url: image29 },
    { id: 30 + 'resource-place', url: image30 },
    { id: 31 + 'resource-place', url: image31 },
    { id: 32 + 'resource-place', url: image32 },
    { id: 33 + 'resource-place', url: image33 },
    { id: 34 + 'resource-place', url: image34 },
    { id: 35 + 'resource-place', url: image35 },
    { id: 36 + 'resource-place', url: image36 },
    { id: 37 + 'resource-place', url: image37 },
    { id: 38 + 'resource-place', url: image38 },
    { id: 39 + 'resource-place', url: image39 },
    { id: 40 + 'resource-place', url: image40 },
    { id: 41 + 'resource-place', url: image41 },
    { id: 42 + 'resource-place', url: image42 },
    { id: 43 + 'resource-place', url: image43 },
    { id: 44 + 'resource-place', url: image44 },
    { id: 45 + 'resource-place', url: image45 },
    { id: 46 + 'resource-place', url: image46 },
    { id: 47 + 'resource-place', url: image47 },
    { id: 48 + 'resource-place', url: image48 },
    { id: 49 + 'resource-place', url: image49 },
    { id: 50 + 'resource-place', url: image50 },
    { id: 51 + 'resource-place', url: image51 },
    { id: 52 + 'resource-place', url: image52 },
    { id: 53 + 'resource-place', url: image53 },
    { id: 54 + 'resource-place', url: image54 },
    { id: 55 + 'resource-place', url: image55 },
    { id: 56 + 'resource-place', url: image56 },
    { id: 57 + 'resource-place', url: image57 },
    { id: 58 + 'resource-place', url: image58 },
    { id: 59 + 'resource-place', url: image59 },
    { id: 60 + 'resource-place', url: image60 },
    { id: 61 + 'resource-place', url: image61 },
    { id: 62 + 'resource-place', url: image62 },
    { id: 63 + 'resource-place', url: image63 },
    { id: 64 + 'resource-place', url: image64 },
    { id: 65 + 'resource-place', url: image65 },
    { id: 66 + 'resource-place', url: image66 },
    { id: 67 + 'resource-place', url: image67 },
    { id: 68 + 'resource-place', url: image68 },
    { id: 69 + 'resource-place', url: image69 },
    { id: 70 + 'resource-place', url: image70 },
    { id: 71 + 'resource-place', url: image71 },
    { id: 72 + 'resource-place', url: image72 },
    { id: 73 + 'resource-place', url: image73 },
    { id: 74 + 'resource-place', url: image74 },
    { id: 75 + 'resource-place', url: image75 },
    { id: 76 + 'resource-place', url: image76 },
    { id: 77 + 'resource-place', url: image77 },
    { id: 78 + 'resource-place', url: image78 },
    { id: 79 + 'resource-place', url: image79 },
    { id: 80 + 'resource-place', url: image80 },
    { id: 81 + 'resource-place', url: image81 },
    { id: 82 + 'resource-place', url: image82 },
    { id: 83 + 'resource-place', url: image83 },
    { id: 84 + 'resource-place', url: image84 },
    { id: 85 + 'resource-place', url: image85 },
    { id: 86 + 'resource-place', url: image86 },
    { id: 87 + 'resource-place', url: image87 },
    { id: 88 + 'resource-place', url: image88 },
    { id: 89 + 'resource-place', url: image89 },
    { id: 90 + 'resource-place', url: image90 },
    { id: 91 + 'resource-place', url: image91 },
    { id: 92 + 'resource-place', url: image92 },
    { id: 93 + 'resource-place', url: image93 },
    { id: 94 + 'resource-place', url: image94 },
    { id: 95 + 'resource-place', url: image95 },
    { id: 96 + 'resource-place', url: image96 },
    { id: 97 + 'resource-place', url: image97 },
    { id: 98 + 'resource-place', url: image98 },
    { id: 99 + 'resource-place', url: image99 },
    { id: 100 + 'resource-place', url: image100 },
    { id: 101 + 'resource-place', url: image101 },
    { id: 102 + 'resource-place', url: image102 },
    { id: 103 + 'resource-place', url: image103 },
    { id: 104 + 'resource-place', url: image104 },
    { id: 105 + 'resource-place', url: image105 },
    { id: 106 + 'resource-place', url: image106 },
    { id: 107 + 'resource-place', url: image107 },
    { id: 108 + 'resource-place', url: image108 },
    { id: 109 + 'resource-place', url: image109 },
  ],
};
