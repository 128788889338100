import image0 from '../images/decks/resource-basis/0.jpg';
import image1 from '../images/decks/resource-basis/1.jpg';
import image2 from '../images/decks/resource-basis/2.jpg';
import image3 from '../images/decks/resource-basis/3.jpg';
import image4 from '../images/decks/resource-basis/4.jpg';
import image5 from '../images/decks/resource-basis/5.jpg';
import image6 from '../images/decks/resource-basis/6.jpg';
import image7 from '../images/decks/resource-basis/7.jpg';
import image8 from '../images/decks/resource-basis/8.jpg';
import image9 from '../images/decks/resource-basis/9.jpg';
import image10 from '../images/decks/resource-basis/10.jpg';
import image11 from '../images/decks/resource-basis/11.jpg';
import image12 from '../images/decks/resource-basis/12.jpg';
import image13 from '../images/decks/resource-basis/13.jpg';
import image14 from '../images/decks/resource-basis/14.jpg';
import image15 from '../images/decks/resource-basis/15.jpg';
import image16 from '../images/decks/resource-basis/16.jpg';
import image17 from '../images/decks/resource-basis/17.jpg';
import image18 from '../images/decks/resource-basis/18.jpg';
import image19 from '../images/decks/resource-basis/19.jpg';
import image20 from '../images/decks/resource-basis/20.jpg';
import image21 from '../images/decks/resource-basis/21.jpg';
import image22 from '../images/decks/resource-basis/22.jpg';
import image23 from '../images/decks/resource-basis/23.jpg';
import image24 from '../images/decks/resource-basis/24.jpg';
import image25 from '../images/decks/resource-basis/25.jpg';
import image26 from '../images/decks/resource-basis/26.jpg';
import image27 from '../images/decks/resource-basis/27.jpg';
import image28 from '../images/decks/resource-basis/28.jpg';
import image29 from '../images/decks/resource-basis/29.jpg';

export const resourceBasis = {
    title: 'Основа ресурса',
    cards: [
        { id: 0 + 'resource-basis', url: image0 },
        { id: 1 + 'resource-basis', url: image1 },
        { id: 2 + 'resource-basis', url: image2 },
        { id: 3 + 'resource-basis', url: image3 },
        { id: 4 + 'resource-basis', url: image4 },
        { id: 5 + 'resource-basis', url: image5 },
        { id: 6 + 'resource-basis', url: image6 },
        { id: 7 + 'resource-basis', url: image7 },
        { id: 8 + 'resource-basis', url: image8 },
        { id: 9 + 'resource-basis', url: image9 },
        { id: 10 + 'resource-basis', url: image10 },
        { id: 11 + 'resource-basis', url: image11 },
        { id: 12 + 'resource-basis', url: image12 },
        { id: 13 + 'resource-basis', url: image13 },
        { id: 14 + 'resource-basis', url: image14 },
        { id: 15 + 'resource-basis', url: image15 },
        { id: 16 + 'resource-basis', url: image16 },
        { id: 17 + 'resource-basis', url: image17 },
        { id: 18 + 'resource-basis', url: image18 },
        { id: 19 + 'resource-basis', url: image19 },
        { id: 20 + 'resource-basis', url: image20 },
        { id: 21 + 'resource-basis', url: image21 },
        { id: 22 + 'resource-basis', url: image22 },
        { id: 23 + 'resource-basis', url: image23 },
        { id: 24 + 'resource-basis', url: image24 },
        { id: 25 + 'resource-basis', url: image25 },
        { id: 26 + 'resource-basis', url: image26 },
        { id: 27 + 'resource-basis', url: image27 },
        { id: 28 + 'resource-basis', url: image28 },
        { id: 29 + 'resource-basis', url: image29 },
    ],
};
