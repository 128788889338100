import { FC, useState } from 'react';
import { Skeleton } from 'antd';
import s from './style.module.scss';
import { TypeCard } from '../../types';
import { CardBackground } from '../card-background';

type Props = {
  card: TypeCard;
  className?: string;
  openCards: boolean;
  onClick: () => void;
  deck: string;
};

export const CardOnList: FC<Props> = (props) => {
  const { card, className, openCards, onClick, deck } = props;
  const [isLoading, setIsLoading] = useState(true);

  return (
    <div className={className ? `${s.cardContainer} ${className}` : s.cardContainer} onClick={onClick}>
      {openCards ? (
        <>
          {isLoading && <Skeleton.Image className={s.cardImageSkeleton} active />}
          <img
            style={{ display: isLoading ? 'none' : 'block' }}
            className={s.cardImage}
            src={card.url}
            alt="card"
            onLoad={() => setIsLoading(false)}
          />
        </>
      ) : (
        <div className={s.cardBackgroundWrapper}>
          <CardBackground cardDeckName={deck} />
        </div>
      )}
    </div>
  );
};
