import { useCallback, useEffect, useState } from 'react';
import { Layout } from 'antd';
import { Board } from '../board';
import { TypeCard } from '../../types';
import { CardList } from '../card-list';
import { data } from '../../data/data';
// import { useSelector } from '../../store/types';
// import { LoginForm } from '../login-form';
import { Header } from './header';
import { Sider } from './sider';

export const MainLayout = () => {
  // const isAuth = useSelector((state) => state.user.isAuth);
  const [deck, setDeck] = useState('allegories');
  const [openCards, setOpenCards] = useState(true);
  const [collapsed, setCollapsed] = useState(false);
  const [cardList, setCardList] = useState<TypeCard[]>([]);
  const [boardCards, setBoardCards] = useState<TypeCard[]>([]);

  useEffect(() => {
    const deck = localStorage.getItem('deck');

    if (!deck) {
      return;
    }

    setDeck(deck);

    const cards = localStorage.getItem('cards');

    if (!cards) {
      return;
    }

    const cardList: TypeCard[] = JSON.parse(cards);

    setBoardCards(cardList);
  }, []);

  useEffect(() => {
    const currentCards = data[deck].cards.filter((item) => {
      const currentCard = boardCards.find((card) => card.id === item.id);
      return !currentCard;
    });
    setCardList(currentCards);
  }, [deck, boardCards.length]);

  const handlerOnRemoveBoard = useCallback((item: TypeCard) => {
    setBoardCards((prevState) => prevState.filter((card) => card.id !== item.id));
    setCardList((prevState) => [...prevState, item]);
  }, []);

  const shuffleCardList = useCallback(() => {
    setCardList((prevState) =>
      prevState
        .map((item) => [Math.random(), item])
        .sort()
        .map((item) => item[1] as TypeCard)
    );
  }, []);

  const saveCardList = (card: TypeCard) => {
    const cards = localStorage.getItem('cards');

    if (!cards) {
      localStorage.setItem('cards', JSON.stringify([card]));
      return;
    }

    const currentCards = JSON.parse(cards);

    if (currentCards?.length) {
      localStorage.setItem('cards', JSON.stringify([...currentCards, card]));
    }
  };

  const addItemToBoard = useCallback((item: TypeCard) => {
    setBoardCards((prevState) => {
      const currentCard = prevState.find((card) => card.id === item.id);
      if (currentCard) {
        return prevState.map((card) => {
          if (card.id === item.id) {
            return item;
          }

          return card;
        });
      }
      return [...prevState, item];
    });

    setCardList((prevState) => prevState.filter((card) => card.id !== item.id));
    saveCardList(item);
  }, []);

  const clearBoard = () => {
    setCardList((prevState) => [...prevState, ...boardCards]);
    setBoardCards([]);
    localStorage.removeItem('cards');
  };

  const handlerSetDeck = useCallback((deck: string) => {
    localStorage.setItem('deck', deck);
    setDeck(deck);
  }, []);

  // if (!isAuth) {
  //   return <LoginForm />;
  // }

  return (
    <Layout>
      <Sider deck={deck} setDeck={handlerSetDeck} collapsed={collapsed} />

      <Layout>
        <Header collapsed={collapsed} setCollapsed={setCollapsed} />

        <Board cards={boardCards} openCards={openCards} onRemove={handlerOnRemoveBoard} />
        <CardList
          openCards={openCards}
          setOpenCards={setOpenCards}
          cardList={cardList}
          shuffleCardList={shuffleCardList}
          addCardToBoard={addItemToBoard}
          deck={data[deck].title}
          clearBoard={clearBoard}
        />
      </Layout>
    </Layout>
  );
};
