import image0 from '../images/decks/person/0.png';
import image1 from '../images/decks/person/1.png';
import image2 from '../images/decks/person/2.png';
import image3 from '../images/decks/person/3.png';
import image4 from '../images/decks/person/4.png';
import image5 from '../images/decks/person/5.png';
import image6 from '../images/decks/person/6.png';
import image7 from '../images/decks/person/7.png';
import image8 from '../images/decks/person/8.png';
import image9 from '../images/decks/person/9.png';
import image10 from '../images/decks/person/10.png';
import image11 from '../images/decks/person/11.png';
import image12 from '../images/decks/person/12.png';
import image13 from '../images/decks/person/13.png';
import image14 from '../images/decks/person/14.png';
import image15 from '../images/decks/person/15.png';
import image16 from '../images/decks/person/16.png';
import image17 from '../images/decks/person/17.png';
import image18 from '../images/decks/person/18.png';
import image19 from '../images/decks/person/19.png';
import image20 from '../images/decks/person/20.png';
import image21 from '../images/decks/person/21.png';
import image22 from '../images/decks/person/22.png';
import image23 from '../images/decks/person/23.png';
import image24 from '../images/decks/person/24.png';
import image25 from '../images/decks/person/25.png';
import image26 from '../images/decks/person/26.png';
import image27 from '../images/decks/person/27.png';
import image28 from '../images/decks/person/28.png';
import image29 from '../images/decks/person/29.png';
import image30 from '../images/decks/person/30.png';
import image31 from '../images/decks/person/31.png';
import image32 from '../images/decks/person/32.png';
import image33 from '../images/decks/person/33.png';
import image34 from '../images/decks/person/34.png';
import image35 from '../images/decks/person/35.png';
import image36 from '../images/decks/person/36.png';
import image37 from '../images/decks/person/37.png';
import image38 from '../images/decks/person/38.png';
import image39 from '../images/decks/person/39.png';
import image40 from '../images/decks/person/40.png';
import image41 from '../images/decks/person/41.png';
import image42 from '../images/decks/person/42.png';
import image43 from '../images/decks/person/43.png';
import image44 from '../images/decks/person/44.png';
import image45 from '../images/decks/person/45.png';
import image46 from '../images/decks/person/46.png';
import image47 from '../images/decks/person/47.png';
import image48 from '../images/decks/person/48.png';
import image49 from '../images/decks/person/49.png';
import image50 from '../images/decks/person/50.png';
import image51 from '../images/decks/person/51.png';
import image52 from '../images/decks/person/52.png';
import image53 from '../images/decks/person/53.png';
import image54 from '../images/decks/person/54.png';
import image55 from '../images/decks/person/55.png';
import image56 from '../images/decks/person/56.png';
import image57 from '../images/decks/person/57.png';
import image58 from '../images/decks/person/58.png';
import image59 from '../images/decks/person/59.png';
import image60 from '../images/decks/person/60.png';
import image61 from '../images/decks/person/61.png';
import image62 from '../images/decks/person/62.png';
import image63 from '../images/decks/person/63.png';
import image64 from '../images/decks/person/64.png';
import image65 from '../images/decks/person/65.png';
import image66 from '../images/decks/person/66.png';
import image67 from '../images/decks/person/67.png';
import image68 from '../images/decks/person/68.png';
import image69 from '../images/decks/person/69.png';
import image70 from '../images/decks/person/70.png';
import image71 from '../images/decks/person/71.png';
import image72 from '../images/decks/person/72.png';
import image73 from '../images/decks/person/73.png';
import image74 from '../images/decks/person/74.png';
import image75 from '../images/decks/person/75.png';
import image76 from '../images/decks/person/76.png';

export const person = {
  title: 'Персона',
  cards: [
    { id: 0 + 'person', url: image0 },
    { id: 1 + 'person', url: image1 },
    { id: 2 + 'person', url: image2 },
    { id: 3 + 'person', url: image3 },
    { id: 4 + 'person', url: image4 },
    { id: 5 + 'person', url: image5 },
    { id: 6 + 'person', url: image6 },
    { id: 7 + 'person', url: image7 },
    { id: 8 + 'person', url: image8 },
    { id: 9 + 'person', url: image9 },
    { id: 10 + 'person', url: image10 },
    { id: 11 + 'person', url: image11 },
    { id: 12 + 'person', url: image12 },
    { id: 13 + 'person', url: image13 },
    { id: 14 + 'person', url: image14 },
    { id: 15 + 'person', url: image15 },
    { id: 16 + 'person', url: image16 },
    { id: 17 + 'person', url: image17 },
    { id: 18 + 'person', url: image18 },
    { id: 19 + 'person', url: image19 },
    { id: 20 + 'person', url: image20 },
    { id: 21 + 'person', url: image21 },
    { id: 22 + 'person', url: image22 },
    { id: 23 + 'person', url: image23 },
    { id: 24 + 'person', url: image24 },
    { id: 25 + 'person', url: image25 },
    { id: 26 + 'person', url: image26 },
    { id: 27 + 'person', url: image27 },
    { id: 28 + 'person', url: image28 },
    { id: 29 + 'person', url: image29 },
    { id: 30 + 'person', url: image30 },
    { id: 31 + 'person', url: image31 },
    { id: 32 + 'person', url: image32 },
    { id: 33 + 'person', url: image33 },
    { id: 34 + 'person', url: image34 },
    { id: 35 + 'person', url: image35 },
    { id: 36 + 'person', url: image36 },
    { id: 37 + 'person', url: image37 },
    { id: 38 + 'person', url: image38 },
    { id: 39 + 'person', url: image39 },
    { id: 40 + 'person', url: image40 },
    { id: 41 + 'person', url: image41 },
    { id: 42 + 'person', url: image42 },
    { id: 43 + 'person', url: image43 },
    { id: 44 + 'person', url: image44 },
    { id: 45 + 'person', url: image45 },
    { id: 46 + 'person', url: image46 },
    { id: 47 + 'person', url: image47 },
    { id: 48 + 'person', url: image48 },
    { id: 49 + 'person', url: image49 },
    { id: 50 + 'person', url: image50 },
    { id: 51 + 'person', url: image51 },
    { id: 52 + 'person', url: image52 },
    { id: 53 + 'person', url: image53 },
    { id: 54 + 'person', url: image54 },
    { id: 55 + 'person', url: image55 },
    { id: 56 + 'person', url: image56 },
    { id: 57 + 'person', url: image57 },
    { id: 58 + 'person', url: image58 },
    { id: 59 + 'person', url: image59 },
    { id: 60 + 'person', url: image60 },
    { id: 61 + 'person', url: image61 },
    { id: 62 + 'person', url: image62 },
    { id: 63 + 'person', url: image63 },
    { id: 64 + 'person', url: image64 },
    { id: 65 + 'person', url: image65 },
    { id: 66 + 'person', url: image66 },
    { id: 67 + 'person', url: image67 },
    { id: 68 + 'person', url: image68 },
    { id: 69 + 'person', url: image69 },
    { id: 70 + 'person', url: image70 },
    { id: 71 + 'person', url: image71 },
    { id: 72 + 'person', url: image72 },
    { id: 73 + 'person', url: image73 },
    { id: 74 + 'person', url: image74 },
    { id: 75 + 'person', url: image75 },
    { id: 76 + 'person', url: image76 },
  ],
};
