import { allegories } from './allegories';
import { animals } from './animals';
import { colors } from './colors';
import { ohPictures } from './oh-pictures';
import { places } from './place';
import { resources } from './resources';
import { roads } from './roads';
import { woods } from './woods';
import { giftsFromParents } from './gifts-from-parents';
import { childhood } from './childhood';
import { soulLessons } from './soul-lessons';
import { smallHappiness } from './small-happiness';
import { skills } from './skills';
import { resourcePlace } from './resource-place';
import { resourceBasis } from './resource-basis';
import { source } from './source';
import { imageWoman } from './image-woman';
import { person } from './person';
import { solution } from './solution';
import { tandu } from './tandu';
import { ecco } from './ecco';
import { injury } from './injury';
import { cope } from './cope';

export const data = {
  allegories,
  animals,
  colors,
  ohPictures,
  places,
  resources,
  roads,
  woods,
  giftsFromParents,
  childhood,
  soulLessons,
  smallHappiness,
  skills,
  resourcePlace,
  resourceBasis,
  source,
  imageWoman,
  person,
  solution,
  tandu,
  ecco,
  injury,
  cope,
};
