import image0 from '../images/decks/place/0.jpg';
import image1 from '../images/decks/place/1.jpg';
import image2 from '../images/decks/place/2.jpg';
import image3 from '../images/decks/place/3.jpg';
import image4 from '../images/decks/place/4.jpg';
import image5 from '../images/decks/place/5.jpg';
import image6 from '../images/decks/place/6.jpg';
import image7 from '../images/decks/place/7.jpg';
import image8 from '../images/decks/place/8.jpg';
import image9 from '../images/decks/place/9.jpg';
import image10 from '../images/decks/place/10.jpg';
import image11 from '../images/decks/place/11.jpg';
import image12 from '../images/decks/place/12.jpg';
import image13 from '../images/decks/place/13.jpg';
import image14 from '../images/decks/place/14.jpg';
import image15 from '../images/decks/place/15.jpg';
import image16 from '../images/decks/place/16.jpg';
import image17 from '../images/decks/place/17.jpg';
import image18 from '../images/decks/place/18.jpg';
import image19 from '../images/decks/place/19.jpg';
import image20 from '../images/decks/place/20.jpg';
import image21 from '../images/decks/place/21.jpg';
import image22 from '../images/decks/place/22.jpg';
import image23 from '../images/decks/place/23.jpg';
import image24 from '../images/decks/place/24.jpg';
import image25 from '../images/decks/place/25.jpg';
import image26 from '../images/decks/place/26.jpg';
import image27 from '../images/decks/place/27.jpg';
import image28 from '../images/decks/place/28.jpg';
import image29 from '../images/decks/place/29.jpg';
import image30 from '../images/decks/place/30.jpg';
import image31 from '../images/decks/place/31.jpg';
import image32 from '../images/decks/place/32.jpg';
import image33 from '../images/decks/place/33.jpg';
import image34 from '../images/decks/place/34.jpg';
import image35 from '../images/decks/place/35.jpg';
import image36 from '../images/decks/place/36.jpg';
import image37 from '../images/decks/place/37.jpg';
import image38 from '../images/decks/place/38.jpg';
import image39 from '../images/decks/place/39.jpg';
import image40 from '../images/decks/place/40.jpg';
import image41 from '../images/decks/place/41.jpg';
import image42 from '../images/decks/place/42.jpg';
import image43 from '../images/decks/place/43.jpg';
import image44 from '../images/decks/place/44.jpg';
import image45 from '../images/decks/place/45.jpg';
import image46 from '../images/decks/place/46.jpg';
import image47 from '../images/decks/place/47.jpg';
import image48 from '../images/decks/place/48.jpg';
import image49 from '../images/decks/place/49.jpg';
import image50 from '../images/decks/place/50.jpg';
import image51 from '../images/decks/place/51.jpg';
import image52 from '../images/decks/place/52.jpg';
import image53 from '../images/decks/place/53.jpg';
import image54 from '../images/decks/place/54.jpg';
import image55 from '../images/decks/place/55.jpg';
import image56 from '../images/decks/place/56.jpg';
import image57 from '../images/decks/place/57.jpg';
import image58 from '../images/decks/place/58.jpg';
import image59 from '../images/decks/place/59.jpg';
import image60 from '../images/decks/place/60.jpg';
import image61 from '../images/decks/place/61.jpg';
import image62 from '../images/decks/place/62.jpg';
import image63 from '../images/decks/place/63.jpg';
import image64 from '../images/decks/place/64.jpg';
import image65 from '../images/decks/place/65.jpg';
import image66 from '../images/decks/place/66.jpg';
import image67 from '../images/decks/place/67.jpg';
import image68 from '../images/decks/place/68.jpg';
import image69 from '../images/decks/place/69.jpg';
import image70 from '../images/decks/place/70.jpg';
import image71 from '../images/decks/place/71.jpg';
import image72 from '../images/decks/place/72.jpg';
import image73 from '../images/decks/place/73.jpg';
import image74 from '../images/decks/place/74.jpg';
import image75 from '../images/decks/place/75.jpg';
import image76 from '../images/decks/place/76.jpg';
import image77 from '../images/decks/place/77.jpg';
import image78 from '../images/decks/place/78.jpg';
import image79 from '../images/decks/place/79.jpg';
import image80 from '../images/decks/place/80.jpg';
import image81 from '../images/decks/place/81.jpg';
import image82 from '../images/decks/place/82.jpg';
import image83 from '../images/decks/place/83.jpg';
import image84 from '../images/decks/place/84.jpg';
import image85 from '../images/decks/place/85.jpg';
import image86 from '../images/decks/place/86.jpg';
import image87 from '../images/decks/place/87.jpg';
import image88 from '../images/decks/place/88.jpg';
import image89 from '../images/decks/place/89.jpg';
import image90 from '../images/decks/place/90.jpg';
import image91 from '../images/decks/place/91.jpg';
import image92 from '../images/decks/place/92.jpg';
import image93 from '../images/decks/place/93.jpg';
import image94 from '../images/decks/place/94.jpg';
import image95 from '../images/decks/place/95.jpg';
import image96 from '../images/decks/place/96.jpg';
import image97 from '../images/decks/place/97.jpg';
import image98 from '../images/decks/place/98.jpg';
import image99 from '../images/decks/place/99.jpg';
import image100 from '../images/decks/place/100.jpg';
import image101 from '../images/decks/place/101.jpg';
import image102 from '../images/decks/place/102.jpg';
import image103 from '../images/decks/place/103.jpg';
import image104 from '../images/decks/place/104.jpg';
import image105 from '../images/decks/place/105.jpg';
import image106 from '../images/decks/place/106.jpg';
import image107 from '../images/decks/place/107.jpg';
import image108 from '../images/decks/place/108.jpg';
import image109 from '../images/decks/place/109.jpg';

export const places = {
  title: 'Место',
  cards: [
    { id: 0 + 'places', url: image0 },
    { id: 1 + 'places', url: image1 },
    { id: 2 + 'places', url: image2 },
    { id: 3 + 'places', url: image3 },
    { id: 4 + 'places', url: image4 },
    { id: 5 + 'places', url: image5 },
    { id: 6 + 'places', url: image6 },
    { id: 7 + 'places', url: image7 },
    { id: 8 + 'places', url: image8 },
    { id: 9 + 'places', url: image9 },
    { id: 10 + 'places', url: image10 },
    { id: 11 + 'places', url: image11 },
    { id: 12 + 'places', url: image12 },
    { id: 13 + 'places', url: image13 },
    { id: 14 + 'places', url: image14 },
    { id: 15 + 'places', url: image15 },
    { id: 16 + 'places', url: image16 },
    { id: 17 + 'places', url: image17 },
    { id: 18 + 'places', url: image18 },
    { id: 19 + 'places', url: image19 },
    { id: 20 + 'places', url: image20 },
    { id: 21 + 'places', url: image21 },
    { id: 22 + 'places', url: image22 },
    { id: 23 + 'places', url: image23 },
    { id: 24 + 'places', url: image24 },
    { id: 25 + 'places', url: image25 },
    { id: 26 + 'places', url: image26 },
    { id: 27 + 'places', url: image27 },
    { id: 28 + 'places', url: image28 },
    { id: 29 + 'places', url: image29 },
    { id: 30 + 'places', url: image30 },
    { id: 31 + 'places', url: image31 },
    { id: 32 + 'places', url: image32 },
    { id: 33 + 'places', url: image33 },
    { id: 34 + 'places', url: image34 },
    { id: 35 + 'places', url: image35 },
    { id: 36 + 'places', url: image36 },
    { id: 37 + 'places', url: image37 },
    { id: 38 + 'places', url: image38 },
    { id: 39 + 'places', url: image39 },
    { id: 40 + 'places', url: image40 },
    { id: 41 + 'places', url: image41 },
    { id: 42 + 'places', url: image42 },
    { id: 43 + 'places', url: image43 },
    { id: 44 + 'places', url: image44 },
    { id: 45 + 'places', url: image45 },
    { id: 46 + 'places', url: image46 },
    { id: 47 + 'places', url: image47 },
    { id: 48 + 'places', url: image48 },
    { id: 49 + 'places', url: image49 },
    { id: 50 + 'places', url: image50 },
    { id: 51 + 'places', url: image51 },
    { id: 52 + 'places', url: image52 },
    { id: 53 + 'places', url: image53 },
    { id: 54 + 'places', url: image54 },
    { id: 55 + 'places', url: image55 },
    { id: 56 + 'places', url: image56 },
    { id: 57 + 'places', url: image57 },
    { id: 58 + 'places', url: image58 },
    { id: 59 + 'places', url: image59 },
    { id: 60 + 'places', url: image60 },
    { id: 61 + 'places', url: image61 },
    { id: 62 + 'places', url: image62 },
    { id: 63 + 'places', url: image63 },
    { id: 64 + 'places', url: image64 },
    { id: 65 + 'places', url: image65 },
    { id: 66 + 'places', url: image66 },
    { id: 67 + 'places', url: image67 },
    { id: 68 + 'places', url: image68 },
    { id: 69 + 'places', url: image69 },
    { id: 70 + 'places', url: image70 },
    { id: 71 + 'places', url: image71 },
    { id: 72 + 'places', url: image72 },
    { id: 73 + 'places', url: image73 },
    { id: 74 + 'places', url: image74 },
    { id: 75 + 'places', url: image75 },
    { id: 76 + 'places', url: image76 },
    { id: 77 + 'places', url: image77 },
    { id: 78 + 'places', url: image78 },
    { id: 79 + 'places', url: image79 },
    { id: 80 + 'places', url: image80 },
    { id: 81 + 'places', url: image81 },
    { id: 82 + 'places', url: image82 },
    { id: 83 + 'places', url: image83 },
    { id: 84 + 'places', url: image84 },
    { id: 85 + 'places', url: image85 },
    { id: 86 + 'places', url: image86 },
    { id: 87 + 'places', url: image87 },
    { id: 88 + 'places', url: image88 },
    { id: 89 + 'places', url: image89 },
    { id: 90 + 'places', url: image90 },
    { id: 91 + 'places', url: image91 },
    { id: 92 + 'places', url: image92 },
    { id: 93 + 'places', url: image93 },
    { id: 94 + 'places', url: image94 },
    { id: 95 + 'places', url: image95 },
    { id: 96 + 'places', url: image96 },
    { id: 97 + 'places', url: image97 },
    { id: 98 + 'places', url: image98 },
    { id: 99 + 'places', url: image99 },
    { id: 100 + 'places', url: image100 },
    { id: 101 + 'places', url: image101 },
    { id: 102 + 'places', url: image102 },
    { id: 103 + 'places', url: image103 },
    { id: 104 + 'places', url: image104 },
    { id: 105 + 'places', url: image105 },
    { id: 106 + 'places', url: image106 },
    { id: 107 + 'places', url: image107 },
    { id: 108 + 'places', url: image108 },
    { id: 109 + 'places', url: image109 },
  ],
};
