import image0 from '../images/decks/injury/0.jpg';
import image1 from '../images/decks/injury/1.jpg';
import image2 from '../images/decks/injury/2.jpg';
import image3 from '../images/decks/injury/3.jpg';
import image4 from '../images/decks/injury/4.jpg';
import image5 from '../images/decks/injury/5.jpg';
import image6 from '../images/decks/injury/6.jpg';
import image7 from '../images/decks/injury/7.jpg';
import image8 from '../images/decks/injury/8.jpg';
import image9 from '../images/decks/injury/9.jpg';
import image10 from '../images/decks/injury/10.jpg';
import image11 from '../images/decks/injury/11.jpg';
import image12 from '../images/decks/injury/12.jpg';
import image13 from '../images/decks/injury/13.jpg';
import image14 from '../images/decks/injury/14.jpg';
import image15 from '../images/decks/injury/15.jpg';
import image16 from '../images/decks/injury/16.jpg';
import image17 from '../images/decks/injury/17.jpg';
import image18 from '../images/decks/injury/18.jpg';
import image19 from '../images/decks/injury/19.jpg';
import image20 from '../images/decks/injury/20.jpg';
import image21 from '../images/decks/injury/21.jpg';
import image22 from '../images/decks/injury/22.jpg';
import image23 from '../images/decks/injury/23.jpg';
import image24 from '../images/decks/injury/24.jpg';
import image25 from '../images/decks/injury/25.jpg';

export const injury = {
  title: 'Травма',
  cards: [
    { id: 0 + 'injury', url: image0 },
    { id: 1 + 'injury', url: image1 },
    { id: 2 + 'injury', url: image2 },
    { id: 3 + 'injury', url: image3 },
    { id: 4 + 'injury', url: image4 },
    { id: 5 + 'injury', url: image5 },
    { id: 6 + 'injury', url: image6 },
    { id: 7 + 'injury', url: image7 },
    { id: 8 + 'injury', url: image8 },
    { id: 9 + 'injury', url: image9 },
    { id: 10 + 'injury', url: image10 },
    { id: 11 + 'injury', url: image11 },
    { id: 12 + 'injury', url: image12 },
    { id: 13 + 'injury', url: image13 },
    { id: 14 + 'injury', url: image14 },
    { id: 15 + 'injury', url: image15 },
    { id: 16 + 'injury', url: image16 },
    { id: 17 + 'injury', url: image17 },
    { id: 18 + 'injury', url: image18 },
    { id: 19 + 'injury', url: image19 },
    { id: 20 + 'injury', url: image20 },
    { id: 21 + 'injury', url: image21 },
    { id: 22 + 'injury', url: image22 },
    { id: 23 + 'injury', url: image23 },
    { id: 24 + 'injury', url: image24 },
    { id: 25 + 'injury', url: image25 },
  ],
};
