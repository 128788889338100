import image1 from '../images/decks/childhood/1.jpg';
import image2 from '../images/decks/childhood/2.jpg';
import image3 from '../images/decks/childhood/3.jpg';
import image4 from '../images/decks/childhood/4.jpg';
import image5 from '../images/decks/childhood/5.jpg';
import image6 from '../images/decks/childhood/6.jpg';
import image7 from '../images/decks/childhood/7.jpg';
import image8 from '../images/decks/childhood/8.jpg';
import image9 from '../images/decks/childhood/9.jpg';
import image10 from '../images/decks/childhood/10.jpg';
import image11 from '../images/decks/childhood/11.jpg';
import image12 from '../images/decks/childhood/12.jpg';
import image13 from '../images/decks/childhood/13.jpg';
import image14 from '../images/decks/childhood/14.jpg';
import image15 from '../images/decks/childhood/15.jpg';
import image16 from '../images/decks/childhood/16.jpg';
import image17 from '../images/decks/childhood/17.jpg';
import image18 from '../images/decks/childhood/18.jpg';
import image19 from '../images/decks/childhood/19.jpg';
import image20 from '../images/decks/childhood/20.jpg';
import image21 from '../images/decks/childhood/21.jpg';
import image22 from '../images/decks/childhood/22.jpg';
import image23 from '../images/decks/childhood/23.jpg';
import image24 from '../images/decks/childhood/24.jpg';
import image25 from '../images/decks/childhood/25.jpg';
import image26 from '../images/decks/childhood/26.jpg';
import image27 from '../images/decks/childhood/27.jpg';
import image28 from '../images/decks/childhood/28.jpg';
import image29 from '../images/decks/childhood/29.jpg';
import image30 from '../images/decks/childhood/30.jpg';
import image31 from '../images/decks/childhood/31.jpg';
import image32 from '../images/decks/childhood/32.jpg';
import image33 from '../images/decks/childhood/33.jpg';
import image34 from '../images/decks/childhood/34.jpg';
import image35 from '../images/decks/childhood/35.jpg';
import image36 from '../images/decks/childhood/36.jpg';
import image37 from '../images/decks/childhood/37.jpg';
import image38 from '../images/decks/childhood/38.jpg';
import image39 from '../images/decks/childhood/39.jpg';
import image40 from '../images/decks/childhood/40.jpg';
import image41 from '../images/decks/childhood/41.jpg';
import image42 from '../images/decks/childhood/42.jpg';
import image43 from '../images/decks/childhood/43.jpg';
import image44 from '../images/decks/childhood/44.jpg';
import image45 from '../images/decks/childhood/45.jpg';
import image46 from '../images/decks/childhood/46.jpg';
import image47 from '../images/decks/childhood/47.jpg';
import image48 from '../images/decks/childhood/48.jpg';
import image49 from '../images/decks/childhood/49.jpg';
import image50 from '../images/decks/childhood/50.jpg';
import image51 from '../images/decks/childhood/51.jpg';
import image52 from '../images/decks/childhood/52.jpg';
import image53 from '../images/decks/childhood/53.jpg';
import image54 from '../images/decks/childhood/54.jpg';
import image55 from '../images/decks/childhood/55.jpg';
import image56 from '../images/decks/childhood/56.jpg';
import image57 from '../images/decks/childhood/57.jpg';
import image58 from '../images/decks/childhood/58.jpg';
import image59 from '../images/decks/childhood/59.jpg';
import image60 from '../images/decks/childhood/60.jpg';
import image61 from '../images/decks/childhood/61.jpg';
import image62 from '../images/decks/childhood/62.jpg';
import image63 from '../images/decks/childhood/63.jpg';
import image64 from '../images/decks/childhood/64.jpg';
import image65 from '../images/decks/childhood/65.jpg';
import image66 from '../images/decks/childhood/66.jpg';
import image67 from '../images/decks/childhood/67.jpg';
import image68 from '../images/decks/childhood/68.jpg';
import image69 from '../images/decks/childhood/69.jpg';
import image70 from '../images/decks/childhood/70.jpg';
import image71 from '../images/decks/childhood/71.jpg';
import image72 from '../images/decks/childhood/72.jpg';
import image73 from '../images/decks/childhood/73.jpg';
import image74 from '../images/decks/childhood/74.jpg';
import image75 from '../images/decks/childhood/75.jpg';
import image76 from '../images/decks/childhood/76.jpg';
import image77 from '../images/decks/childhood/77.jpg';
import image78 from '../images/decks/childhood/78.jpg';
import image79 from '../images/decks/childhood/79.jpg';
import image80 from '../images/decks/childhood/80.jpg';
import image81 from '../images/decks/childhood/81.jpg';
import image82 from '../images/decks/childhood/82.jpg';
import image83 from '../images/decks/childhood/83.jpg';
import image84 from '../images/decks/childhood/84.jpg';
import image85 from '../images/decks/childhood/85.jpg';
import image86 from '../images/decks/childhood/86.jpg';
import image87 from '../images/decks/childhood/87.jpg';
import image88 from '../images/decks/childhood/88.jpg';
import image89 from '../images/decks/childhood/89.jpg';
import image90 from '../images/decks/childhood/90.jpg';
import image91 from '../images/decks/childhood/91.jpg';
import image92 from '../images/decks/childhood/92.jpg';
import image93 from '../images/decks/childhood/93.jpg';
import image94 from '../images/decks/childhood/94.jpg';
import image95 from '../images/decks/childhood/95.jpg';
import image96 from '../images/decks/childhood/96.jpg';
import image97 from '../images/decks/childhood/97.jpg';
import image98 from '../images/decks/childhood/98.jpg';
import image99 from '../images/decks/childhood/99.jpg';
import image100 from '../images/decks/childhood/100.jpg';
import image101 from '../images/decks/childhood/101.jpg';
import image102 from '../images/decks/childhood/102.jpg';
import image103 from '../images/decks/childhood/103.jpg';
import image104 from '../images/decks/childhood/104.jpg';
import image105 from '../images/decks/childhood/105.jpg';
import image106 from '../images/decks/childhood/106.jpg';
import image107 from '../images/decks/childhood/107.jpg';
import image108 from '../images/decks/childhood/108.jpg';
import image109 from '../images/decks/childhood/109.jpg';
import image110 from '../images/decks/childhood/110.jpg';
import image111 from '../images/decks/childhood/111.jpg';
import image112 from '../images/decks/childhood/112.jpg';
import image113 from '../images/decks/childhood/113.jpg';
import image114 from '../images/decks/childhood/114.jpg';
import image115 from '../images/decks/childhood/115.jpg';
import image116 from '../images/decks/childhood/116.jpg';
import image117 from '../images/decks/childhood/117.jpg';
import image118 from '../images/decks/childhood/118.jpg';
import image119 from '../images/decks/childhood/119.jpg';
import image120 from '../images/decks/childhood/120.jpg';
import image121 from '../images/decks/childhood/121.jpg';
import image122 from '../images/decks/childhood/122.jpg';
import image123 from '../images/decks/childhood/123.jpg';
import image124 from '../images/decks/childhood/124.jpg';
import image125 from '../images/decks/childhood/125.jpg';
import image126 from '../images/decks/childhood/126.jpg';
import image127 from '../images/decks/childhood/127.jpg';
import image128 from '../images/decks/childhood/128.jpg';
import image129 from '../images/decks/childhood/129.jpg';
import image130 from '../images/decks/childhood/130.jpg';
import image131 from '../images/decks/childhood/131.jpg';
import image132 from '../images/decks/childhood/132.jpg';
import image133 from '../images/decks/childhood/133.jpg';
import image134 from '../images/decks/childhood/134.jpg';
import image135 from '../images/decks/childhood/135.jpg';
import image136 from '../images/decks/childhood/136.jpg';
import image137 from '../images/decks/childhood/137.jpg';
import image138 from '../images/decks/childhood/138.jpg';
import image139 from '../images/decks/childhood/139.jpg';
import image140 from '../images/decks/childhood/140.jpg';
import image141 from '../images/decks/childhood/141.jpg';
import image142 from '../images/decks/childhood/142.jpg';
import image143 from '../images/decks/childhood/143.jpg';
import image144 from '../images/decks/childhood/144.jpg';
import image145 from '../images/decks/childhood/145.jpg';
import image146 from '../images/decks/childhood/146.jpg';
import image147 from '../images/decks/childhood/147.jpg';
import image148 from '../images/decks/childhood/148.jpg';
import image149 from '../images/decks/childhood/149.jpg';
import image150 from '../images/decks/childhood/150.jpg';
import image151 from '../images/decks/childhood/151.jpg';
import image152 from '../images/decks/childhood/152.jpg';
import image153 from '../images/decks/childhood/153.jpg';
import image154 from '../images/decks/childhood/154.jpg';
import image155 from '../images/decks/childhood/155.jpg';
import image156 from '../images/decks/childhood/156.jpg';
import image157 from '../images/decks/childhood/157.jpg';
import image158 from '../images/decks/childhood/158.jpg';
import image159 from '../images/decks/childhood/159.jpg';
import image160 from '../images/decks/childhood/160.jpg';
import image161 from '../images/decks/childhood/161.jpg';
import image162 from '../images/decks/childhood/162.jpg';
import image163 from '../images/decks/childhood/163.jpg';
import image164 from '../images/decks/childhood/164.jpg';
import image165 from '../images/decks/childhood/165.jpg';
import image166 from '../images/decks/childhood/166.jpg';
import image167 from '../images/decks/childhood/167.jpg';
import image168 from '../images/decks/childhood/168.jpg';
import image169 from '../images/decks/childhood/169.jpg';
import image170 from '../images/decks/childhood/170.jpg';
import image171 from '../images/decks/childhood/171.jpg';
import image172 from '../images/decks/childhood/172.jpg';
import image173 from '../images/decks/childhood/173.jpg';
import image174 from '../images/decks/childhood/174.jpg';
import image175 from '../images/decks/childhood/175.jpg';
import image176 from '../images/decks/childhood/176.jpg';
import image177 from '../images/decks/childhood/177.jpg';
import image178 from '../images/decks/childhood/178.jpg';
import image179 from '../images/decks/childhood/179.jpg';
import image180 from '../images/decks/childhood/180.jpg';
import image181 from '../images/decks/childhood/181.jpg';
import image182 from '../images/decks/childhood/182.jpg';
import image183 from '../images/decks/childhood/183.jpg';
import image184 from '../images/decks/childhood/184.jpg';
import image185 from '../images/decks/childhood/185.jpg';
import image186 from '../images/decks/childhood/186.jpg';
import image187 from '../images/decks/childhood/187.jpg';
import image188 from '../images/decks/childhood/188.jpg';
import image189 from '../images/decks/childhood/189.jpg';
import image190 from '../images/decks/childhood/190.jpg';
import image191 from '../images/decks/childhood/191.jpg';
import image192 from '../images/decks/childhood/192.jpg';
import image193 from '../images/decks/childhood/193.jpg';
import image194 from '../images/decks/childhood/194.jpg';
import image195 from '../images/decks/childhood/195.jpg';
import image196 from '../images/decks/childhood/196.jpg';
import image197 from '../images/decks/childhood/197.jpg';
import image198 from '../images/decks/childhood/198.jpg';
import image199 from '../images/decks/childhood/199.jpg';
import image200 from '../images/decks/childhood/200.jpg';
import image201 from '../images/decks/childhood/201.jpg';
import image202 from '../images/decks/childhood/202.jpg';
import image203 from '../images/decks/childhood/203.jpg';
import image204 from '../images/decks/childhood/204.jpg';
import image205 from '../images/decks/childhood/205.jpg';
import image206 from '../images/decks/childhood/206.jpg';
import image207 from '../images/decks/childhood/207.jpg';
import image208 from '../images/decks/childhood/208.jpg';
import image209 from '../images/decks/childhood/209.jpg';
import image210 from '../images/decks/childhood/210.jpg';
import image211 from '../images/decks/childhood/211.jpg';
import image212 from '../images/decks/childhood/212.jpg';
import image213 from '../images/decks/childhood/213.jpg';
import image214 from '../images/decks/childhood/214.jpg';
import image215 from '../images/decks/childhood/215.jpg';
import image216 from '../images/decks/childhood/216.jpg';
import image217 from '../images/decks/childhood/217.jpg';
import image218 from '../images/decks/childhood/218.jpg';
import image219 from '../images/decks/childhood/219.jpg';
import image220 from '../images/decks/childhood/220.jpg';
import image221 from '../images/decks/childhood/221.jpg';
import image222 from '../images/decks/childhood/222.jpg';
import image223 from '../images/decks/childhood/223.jpg';
import image224 from '../images/decks/childhood/224.jpg';
import image225 from '../images/decks/childhood/225.jpg';
import image226 from '../images/decks/childhood/226.jpg';
import image227 from '../images/decks/childhood/227.jpg';
import image228 from '../images/decks/childhood/228.jpg';
import image229 from '../images/decks/childhood/229.jpg';
import image230 from '../images/decks/childhood/230.jpg';
import image231 from '../images/decks/childhood/231.jpg';
import image232 from '../images/decks/childhood/232.jpg';
import image233 from '../images/decks/childhood/233.jpg';
import image234 from '../images/decks/childhood/234.jpg';
import image235 from '../images/decks/childhood/235.jpg';
import image236 from '../images/decks/childhood/236.jpg';
import image237 from '../images/decks/childhood/237.jpg';

export const childhood = {
  title: 'Детство',
  cards: [
    { id: 1 + 'childhood', url: image1 },
    { id: 2 + 'childhood', url: image2 },
    { id: 3 + 'childhood', url: image3 },
    { id: 4 + 'childhood', url: image4 },
    { id: 5 + 'childhood', url: image5 },
    { id: 6 + 'childhood', url: image6 },
    { id: 7 + 'childhood', url: image7 },
    { id: 8 + 'childhood', url: image8 },
    { id: 9 + 'childhood', url: image9 },
    { id: 10 + 'childhood', url: image10 },
    { id: 11 + 'childhood', url: image11 },
    { id: 12 + 'childhood', url: image12 },
    { id: 13 + 'childhood', url: image13 },
    { id: 14 + 'childhood', url: image14 },
    { id: 15 + 'childhood', url: image15 },
    { id: 16 + 'childhood', url: image16 },
    { id: 17 + 'childhood', url: image17 },
    { id: 18 + 'childhood', url: image18 },
    { id: 19 + 'childhood', url: image19 },
    { id: 20 + 'childhood', url: image20 },
    { id: 21 + 'childhood', url: image21 },
    { id: 22 + 'childhood', url: image22 },
    { id: 23 + 'childhood', url: image23 },
    { id: 24 + 'childhood', url: image24 },
    { id: 25 + 'childhood', url: image25 },
    { id: 26 + 'childhood', url: image26 },
    { id: 27 + 'childhood', url: image27 },
    { id: 28 + 'childhood', url: image28 },
    { id: 29 + 'childhood', url: image29 },
    { id: 30 + 'childhood', url: image30 },
    { id: 31 + 'childhood', url: image31 },
    { id: 32 + 'childhood', url: image32 },
    { id: 33 + 'childhood', url: image33 },
    { id: 34 + 'childhood', url: image34 },
    { id: 35 + 'childhood', url: image35 },
    { id: 36 + 'childhood', url: image36 },
    { id: 37 + 'childhood', url: image37 },
    { id: 38 + 'childhood', url: image38 },
    { id: 39 + 'childhood', url: image39 },
    { id: 40 + 'childhood', url: image40 },
    { id: 41 + 'childhood', url: image41 },
    { id: 42 + 'childhood', url: image42 },
    { id: 43 + 'childhood', url: image43 },
    { id: 44 + 'childhood', url: image44 },
    { id: 45 + 'childhood', url: image45 },
    { id: 46 + 'childhood', url: image46 },
    { id: 47 + 'childhood', url: image47 },
    { id: 48 + 'childhood', url: image48 },
    { id: 49 + 'childhood', url: image49 },
    { id: 50 + 'childhood', url: image50 },
    { id: 51 + 'childhood', url: image51 },
    { id: 52 + 'childhood', url: image52 },
    { id: 53 + 'childhood', url: image53 },
    { id: 54 + 'childhood', url: image54 },
    { id: 55 + 'childhood', url: image55 },
    { id: 56 + 'childhood', url: image56 },
    { id: 57 + 'childhood', url: image57 },
    { id: 58 + 'childhood', url: image58 },
    { id: 59 + 'childhood', url: image59 },
    { id: 60 + 'childhood', url: image60 },
    { id: 61 + 'childhood', url: image61 },
    { id: 62 + 'childhood', url: image62 },
    { id: 63 + 'childhood', url: image63 },
    { id: 64 + 'childhood', url: image64 },
    { id: 65 + 'childhood', url: image65 },
    { id: 66 + 'childhood', url: image66 },
    { id: 67 + 'childhood', url: image67 },
    { id: 68 + 'childhood', url: image68 },
    { id: 69 + 'childhood', url: image69 },
    { id: 70 + 'childhood', url: image70 },
    { id: 71 + 'childhood', url: image71 },
    { id: 72 + 'childhood', url: image72 },
    { id: 73 + 'childhood', url: image73 },
    { id: 74 + 'childhood', url: image74 },
    { id: 75 + 'childhood', url: image75 },
    { id: 76 + 'childhood', url: image76 },
    { id: 77 + 'childhood', url: image77 },
    { id: 78 + 'childhood', url: image78 },
    { id: 79 + 'childhood', url: image79 },
    { id: 80 + 'childhood', url: image80 },
    { id: 81 + 'childhood', url: image81 },
    { id: 82 + 'childhood', url: image82 },
    { id: 83 + 'childhood', url: image83 },
    { id: 84 + 'childhood', url: image84 },
    { id: 85 + 'childhood', url: image85 },
    { id: 86 + 'childhood', url: image86 },
    { id: 87 + 'childhood', url: image87 },
    { id: 88 + 'childhood', url: image88 },
    { id: 89 + 'childhood', url: image89 },
    { id: 90 + 'childhood', url: image90 },
    { id: 91 + 'childhood', url: image91 },
    { id: 92 + 'childhood', url: image92 },
    { id: 93 + 'childhood', url: image93 },
    { id: 94 + 'childhood', url: image94 },
    { id: 95 + 'childhood', url: image95 },
    { id: 96 + 'childhood', url: image96 },
    { id: 97 + 'childhood', url: image97 },
    { id: 98 + 'childhood', url: image98 },
    { id: 99 + 'childhood', url: image99 },
    { id: 100 + 'childhood', url: image100 },
    { id: 101 + 'childhood', url: image101 },
    { id: 102 + 'childhood', url: image102 },
    { id: 103 + 'childhood', url: image103 },
    { id: 104 + 'childhood', url: image104 },
    { id: 105 + 'childhood', url: image105 },
    { id: 106 + 'childhood', url: image106 },
    { id: 107 + 'childhood', url: image107 },
    { id: 108 + 'childhood', url: image108 },
    { id: 109 + 'childhood', url: image109 },
    { id: 110 + 'childhood', url: image110 },
    { id: 111 + 'childhood', url: image111 },
    { id: 112 + 'childhood', url: image112 },
    { id: 113 + 'childhood', url: image113 },
    { id: 114 + 'childhood', url: image114 },
    { id: 115 + 'childhood', url: image115 },
    { id: 116 + 'childhood', url: image116 },
    { id: 117 + 'childhood', url: image117 },
    { id: 118 + 'childhood', url: image118 },
    { id: 119 + 'childhood', url: image119 },
    { id: 120 + 'childhood', url: image120 },
    { id: 121 + 'childhood', url: image121 },
    { id: 122 + 'childhood', url: image122 },
    { id: 123 + 'childhood', url: image123 },
    { id: 124 + 'childhood', url: image124 },
    { id: 125 + 'childhood', url: image125 },
    { id: 126 + 'childhood', url: image126 },
    { id: 127 + 'childhood', url: image127 },
    { id: 128 + 'childhood', url: image128 },
    { id: 129 + 'childhood', url: image129 },
    { id: 130 + 'childhood', url: image130 },
    { id: 131 + 'childhood', url: image131 },
    { id: 132 + 'childhood', url: image132 },
    { id: 133 + 'childhood', url: image133 },
    { id: 134 + 'childhood', url: image134 },
    { id: 135 + 'childhood', url: image135 },
    { id: 136 + 'childhood', url: image136 },
    { id: 137 + 'childhood', url: image137 },
    { id: 138 + 'childhood', url: image138 },
    { id: 139 + 'childhood', url: image139 },
    { id: 140 + 'childhood', url: image140 },
    { id: 141 + 'childhood', url: image141 },
    { id: 142 + 'childhood', url: image142 },
    { id: 143 + 'childhood', url: image143 },
    { id: 144 + 'childhood', url: image144 },
    { id: 145 + 'childhood', url: image145 },
    { id: 146 + 'childhood', url: image146 },
    { id: 147 + 'childhood', url: image147 },
    { id: 148 + 'childhood', url: image148 },
    { id: 149 + 'childhood', url: image149 },
    { id: 150 + 'childhood', url: image150 },
    { id: 151 + 'childhood', url: image151 },
    { id: 152 + 'childhood', url: image152 },
    { id: 153 + 'childhood', url: image153 },
    { id: 154 + 'childhood', url: image154 },
    { id: 155 + 'childhood', url: image155 },
    { id: 156 + 'childhood', url: image156 },
    { id: 157 + 'childhood', url: image157 },
    { id: 158 + 'childhood', url: image158 },
    { id: 159 + 'childhood', url: image159 },
    { id: 160 + 'childhood', url: image160 },
    { id: 161 + 'childhood', url: image161 },
    { id: 162 + 'childhood', url: image162 },
    { id: 163 + 'childhood', url: image163 },
    { id: 164 + 'childhood', url: image164 },
    { id: 165 + 'childhood', url: image165 },
    { id: 166 + 'childhood', url: image166 },
    { id: 167 + 'childhood', url: image167 },
    { id: 168 + 'childhood', url: image168 },
    { id: 169 + 'childhood', url: image169 },
    { id: 170 + 'childhood', url: image170 },
    { id: 171 + 'childhood', url: image171 },
    { id: 172 + 'childhood', url: image172 },
    { id: 173 + 'childhood', url: image173 },
    { id: 174 + 'childhood', url: image174 },
    { id: 175 + 'childhood', url: image175 },
    { id: 176 + 'childhood', url: image176 },
    { id: 177 + 'childhood', url: image177 },
    { id: 178 + 'childhood', url: image178 },
    { id: 179 + 'childhood', url: image179 },
    { id: 180 + 'childhood', url: image180 },
    { id: 181 + 'childhood', url: image181 },
    { id: 182 + 'childhood', url: image182 },
    { id: 183 + 'childhood', url: image183 },
    { id: 184 + 'childhood', url: image184 },
    { id: 185 + 'childhood', url: image185 },
    { id: 186 + 'childhood', url: image186 },
    { id: 187 + 'childhood', url: image187 },
    { id: 188 + 'childhood', url: image188 },
    { id: 189 + 'childhood', url: image189 },
    { id: 190 + 'childhood', url: image190 },
    { id: 191 + 'childhood', url: image191 },
    { id: 192 + 'childhood', url: image192 },
    { id: 193 + 'childhood', url: image193 },
    { id: 194 + 'childhood', url: image194 },
    { id: 195 + 'childhood', url: image195 },
    { id: 196 + 'childhood', url: image196 },
    { id: 197 + 'childhood', url: image197 },
    { id: 198 + 'childhood', url: image198 },
    { id: 199 + 'childhood', url: image199 },
    { id: 200 + 'childhood', url: image200 },
    { id: 201 + 'childhood', url: image201 },
    { id: 202 + 'childhood', url: image202 },
    { id: 203 + 'childhood', url: image203 },
    { id: 204 + 'childhood', url: image204 },
    { id: 205 + 'childhood', url: image205 },
    { id: 206 + 'childhood', url: image206 },
    { id: 207 + 'childhood', url: image207 },
    { id: 208 + 'childhood', url: image208 },
    { id: 209 + 'childhood', url: image209 },
    { id: 210 + 'childhood', url: image210 },
    { id: 211 + 'childhood', url: image211 },
    { id: 212 + 'childhood', url: image212 },
    { id: 213 + 'childhood', url: image213 },
    { id: 214 + 'childhood', url: image214 },
    { id: 215 + 'childhood', url: image215 },
    { id: 216 + 'childhood', url: image216 },
    { id: 217 + 'childhood', url: image217 },
    { id: 218 + 'childhood', url: image218 },
    { id: 219 + 'childhood', url: image219 },
    { id: 220 + 'childhood', url: image220 },
    { id: 221 + 'childhood', url: image221 },
    { id: 222 + 'childhood', url: image222 },
    { id: 223 + 'childhood', url: image223 },
    { id: 224 + 'childhood', url: image224 },
    { id: 225 + 'childhood', url: image225 },
    { id: 226 + 'childhood', url: image226 },
    { id: 227 + 'childhood', url: image227 },
    { id: 228 + 'childhood', url: image228 },
    { id: 229 + 'childhood', url: image229 },
    { id: 230 + 'childhood', url: image230 },
    { id: 231 + 'childhood', url: image231 },
    { id: 232 + 'childhood', url: image232 },
    { id: 233 + 'childhood', url: image233 },
    { id: 234 + 'childhood', url: image234 },
    { id: 235 + 'childhood', url: image235 },
    { id: 236 + 'childhood', url: image236 },
    { id: 237 + 'childhood', url: image237 },
  ],
};
