import image0 from '../images/decks/ecco/0.png';
import image1 from '../images/decks/ecco/1.png';
import image2 from '../images/decks/ecco/2.png';
import image3 from '../images/decks/ecco/3.png';
import image4 from '../images/decks/ecco/4.png';
import image5 from '../images/decks/ecco/5.png';
import image6 from '../images/decks/ecco/6.png';
import image7 from '../images/decks/ecco/7.png';
import image8 from '../images/decks/ecco/8.png';
import image9 from '../images/decks/ecco/9.png';
import image10 from '../images/decks/ecco/10.png';
import image11 from '../images/decks/ecco/11.png';
import image12 from '../images/decks/ecco/12.png';
import image13 from '../images/decks/ecco/13.png';
import image14 from '../images/decks/ecco/14.png';
import image15 from '../images/decks/ecco/15.png';
import image16 from '../images/decks/ecco/16.png';
import image17 from '../images/decks/ecco/17.png';
import image18 from '../images/decks/ecco/18.png';
import image19 from '../images/decks/ecco/19.png';
import image20 from '../images/decks/ecco/20.png';
import image21 from '../images/decks/ecco/21.png';
import image22 from '../images/decks/ecco/22.png';
import image23 from '../images/decks/ecco/23.png';
import image24 from '../images/decks/ecco/24.png';
import image25 from '../images/decks/ecco/25.png';
import image26 from '../images/decks/ecco/26.png';
import image27 from '../images/decks/ecco/27.jpg';
import image28 from '../images/decks/ecco/28.jpg';
import image29 from '../images/decks/ecco/29.jpg';
import image30 from '../images/decks/ecco/30.jpg';
import image31 from '../images/decks/ecco/31.jpg';
import image32 from '../images/decks/ecco/32.jpg';
import image33 from '../images/decks/ecco/33.jpg';
import image34 from '../images/decks/ecco/34.jpg';
import image35 from '../images/decks/ecco/35.png';
import image36 from '../images/decks/ecco/36.jpg';
import image37 from '../images/decks/ecco/37.jpg';
import image38 from '../images/decks/ecco/38.jpg';
import image39 from '../images/decks/ecco/39.jpg';
import image40 from '../images/decks/ecco/40.jpg';
import image41 from '../images/decks/ecco/41.jpg';
import image42 from '../images/decks/ecco/42.jpg';
import image43 from '../images/decks/ecco/43.jpg';
import image44 from '../images/decks/ecco/44.jpg';
import image45 from '../images/decks/ecco/45.jpg';
import image46 from '../images/decks/ecco/46.jpg';
import image47 from '../images/decks/ecco/47.jpg';
import image48 from '../images/decks/ecco/48.jpg';
import image49 from '../images/decks/ecco/49.jpg';
import image50 from '../images/decks/ecco/50.jpg';
import image51 from '../images/decks/ecco/51.jpg';
import image52 from '../images/decks/ecco/52.jpg';
import image53 from '../images/decks/ecco/53.png';
import image54 from '../images/decks/ecco/54.png';
import image55 from '../images/decks/ecco/55.png';
import image56 from '../images/decks/ecco/56.png';
import image57 from '../images/decks/ecco/57.png';
import image58 from '../images/decks/ecco/58.png';
import image59 from '../images/decks/ecco/59.png';
import image60 from '../images/decks/ecco/60.png';
import image61 from '../images/decks/ecco/61.png';
import image62 from '../images/decks/ecco/62.png';
import image63 from '../images/decks/ecco/63.png';
import image64 from '../images/decks/ecco/64.png';
import image65 from '../images/decks/ecco/65.png';
import image66 from '../images/decks/ecco/66.png';
import image67 from '../images/decks/ecco/67.png';
import image68 from '../images/decks/ecco/68.png';
import image69 from '../images/decks/ecco/69.png';
import image70 from '../images/decks/ecco/70.png';
import image71 from '../images/decks/ecco/71.png';
import image72 from '../images/decks/ecco/72.png';
import image73 from '../images/decks/ecco/73.png';
import image74 from '../images/decks/ecco/74.png';
import image75 from '../images/decks/ecco/75.png';
import image76 from '../images/decks/ecco/76.png';
import image77 from '../images/decks/ecco/77.png';
import image78 from '../images/decks/ecco/78.png';
import image79 from '../images/decks/ecco/79.png';
import image80 from '../images/decks/ecco/80.png';
import image81 from '../images/decks/ecco/81.png';
import image82 from '../images/decks/ecco/82.png';
import image83 from '../images/decks/ecco/83.png';
import image84 from '../images/decks/ecco/84.png';
import image85 from '../images/decks/ecco/85.png';
import image86 from '../images/decks/ecco/86.png';
import image87 from '../images/decks/ecco/87.png';
import image88 from '../images/decks/ecco/88.png';
import image89 from '../images/decks/ecco/89.png';
import image90 from '../images/decks/ecco/90.png';
import image91 from '../images/decks/ecco/91.png';
import image92 from '../images/decks/ecco/92.png';
import image93 from '../images/decks/ecco/93.png';
import image94 from '../images/decks/ecco/94.png';
import image95 from '../images/decks/ecco/95.png';
import image96 from '../images/decks/ecco/96.png';
import image97 from '../images/decks/ecco/97.png';
import image98 from '../images/decks/ecco/98.png';

export const ecco = {
  title: 'Ecco',
  cards: [
    { id: 0 + 'ecco', url: image0 },
    { id: 1 + 'ecco', url: image1 },
    { id: 2 + 'ecco', url: image2 },
    { id: 3 + 'ecco', url: image3 },
    { id: 4 + 'ecco', url: image4 },
    { id: 5 + 'ecco', url: image5 },
    { id: 6 + 'ecco', url: image6 },
    { id: 7 + 'ecco', url: image7 },
    { id: 8 + 'ecco', url: image8 },
    { id: 9 + 'ecco', url: image9 },
    { id: 10 + 'ecco', url: image10 },
    { id: 11 + 'ecco', url: image11 },
    { id: 12 + 'ecco', url: image12 },
    { id: 13 + 'ecco', url: image13 },
    { id: 14 + 'ecco', url: image14 },
    { id: 15 + 'ecco', url: image15 },
    { id: 16 + 'ecco', url: image16 },
    { id: 17 + 'ecco', url: image17 },
    { id: 18 + 'ecco', url: image18 },
    { id: 19 + 'ecco', url: image19 },
    { id: 20 + 'ecco', url: image20 },
    { id: 21 + 'ecco', url: image21 },
    { id: 22 + 'ecco', url: image22 },
    { id: 23 + 'ecco', url: image23 },
    { id: 24 + 'ecco', url: image24 },
    { id: 25 + 'ecco', url: image25 },
    { id: 26 + 'ecco', url: image26 },
    { id: 27 + 'ecco', url: image27 },
    { id: 28 + 'ecco', url: image28 },
    { id: 29 + 'ecco', url: image29 },
    { id: 30 + 'ecco', url: image30 },
    { id: 31 + 'ecco', url: image31 },
    { id: 32 + 'ecco', url: image32 },
    { id: 33 + 'ecco', url: image33 },
    { id: 34 + 'ecco', url: image34 },
    { id: 35 + 'ecco', url: image35 },
    { id: 36 + 'ecco', url: image36 },
    { id: 37 + 'ecco', url: image37 },
    { id: 38 + 'ecco', url: image38 },
    { id: 39 + 'ecco', url: image39 },
    { id: 40 + 'ecco', url: image40 },
    { id: 41 + 'ecco', url: image41 },
    { id: 42 + 'ecco', url: image42 },
    { id: 43 + 'ecco', url: image43 },
    { id: 44 + 'ecco', url: image44 },
    { id: 45 + 'ecco', url: image45 },
    { id: 46 + 'ecco', url: image46 },
    { id: 47 + 'ecco', url: image47 },
    { id: 48 + 'ecco', url: image48 },
    { id: 49 + 'ecco', url: image49 },
    { id: 50 + 'ecco', url: image50 },
    { id: 51 + 'ecco', url: image51 },
    { id: 52 + 'ecco', url: image52 },
    { id: 53 + 'ecco', url: image53 },
    { id: 54 + 'ecco', url: image54 },
    { id: 55 + 'ecco', url: image55 },
    { id: 56 + 'ecco', url: image56 },
    { id: 57 + 'ecco', url: image57 },
    { id: 58 + 'ecco', url: image58 },
    { id: 59 + 'ecco', url: image59 },
    { id: 60 + 'ecco', url: image60 },
    { id: 61 + 'ecco', url: image61 },
    { id: 62 + 'ecco', url: image62 },
    { id: 63 + 'ecco', url: image63 },
    { id: 64 + 'ecco', url: image64 },
    { id: 65 + 'ecco', url: image65 },
    { id: 66 + 'ecco', url: image66 },
    { id: 67 + 'ecco', url: image67 },
    { id: 68 + 'ecco', url: image68 },
    { id: 69 + 'ecco', url: image69 },
    { id: 70 + 'ecco', url: image70 },
    { id: 71 + 'ecco', url: image71 },
    { id: 72 + 'ecco', url: image72 },
    { id: 73 + 'ecco', url: image73 },
    { id: 74 + 'ecco', url: image74 },
    { id: 75 + 'ecco', url: image75 },
    { id: 76 + 'ecco', url: image76 },
    { id: 77 + 'ecco', url: image77 },
    { id: 78 + 'ecco', url: image78 },
    { id: 79 + 'ecco', url: image79 },
    { id: 80 + 'ecco', url: image80 },
    { id: 81 + 'ecco', url: image81 },
    { id: 82 + 'ecco', url: image82 },
    { id: 83 + 'ecco', url: image83 },
    { id: 84 + 'ecco', url: image84 },
    { id: 85 + 'ecco', url: image85 },
    { id: 86 + 'ecco', url: image86 },
    { id: 87 + 'ecco', url: image87 },
    { id: 88 + 'ecco', url: image88 },
    { id: 89 + 'ecco', url: image89 },
    { id: 90 + 'ecco', url: image90 },
    { id: 91 + 'ecco', url: image91 },
    { id: 92 + 'ecco', url: image92 },
    { id: 93 + 'ecco', url: image93 },
    { id: 94 + 'ecco', url: image94 },
    { id: 95 + 'ecco', url: image95 },
    { id: 96 + 'ecco', url: image96 },
    { id: 97 + 'ecco', url: image97 },
    { id: 98 + 'ecco', url: image98 },
  ],
};
