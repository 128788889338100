import image0 from '../images/decks/image-woman/0.png';
import image1 from '../images/decks/image-woman/1.png';
import image2 from '../images/decks/image-woman/2.png';
import image3 from '../images/decks/image-woman/3.png';
import image4 from '../images/decks/image-woman/4.png';
import image5 from '../images/decks/image-woman/5.png';
import image6 from '../images/decks/image-woman/6.png';
import image7 from '../images/decks/image-woman/7.png';
import image8 from '../images/decks/image-woman/8.png';
import image9 from '../images/decks/image-woman/9.png';
import image10 from '../images/decks/image-woman/10.png';
import image11 from '../images/decks/image-woman/11.png';
import image12 from '../images/decks/image-woman/12.png';
import image13 from '../images/decks/image-woman/13.png';
import image14 from '../images/decks/image-woman/14.png';
import image15 from '../images/decks/image-woman/15.png';
import image16 from '../images/decks/image-woman/16.png';
import image17 from '../images/decks/image-woman/17.png';
import image18 from '../images/decks/image-woman/18.png';
import image19 from '../images/decks/image-woman/19.png';
import image20 from '../images/decks/image-woman/20.png';
import image21 from '../images/decks/image-woman/21.png';
import image22 from '../images/decks/image-woman/22.png';
import image23 from '../images/decks/image-woman/23.png';
import image24 from '../images/decks/image-woman/24.png';
import image25 from '../images/decks/image-woman/25.png';
import image26 from '../images/decks/image-woman/26.png';
import image27 from '../images/decks/image-woman/27.png';
import image28 from '../images/decks/image-woman/28.png';
import image29 from '../images/decks/image-woman/29.png';
import image30 from '../images/decks/image-woman/30.png';
import image31 from '../images/decks/image-woman/31.png';
import image32 from '../images/decks/image-woman/32.png';
import image33 from '../images/decks/image-woman/33.png';
import image34 from '../images/decks/image-woman/34.png';
import image35 from '../images/decks/image-woman/35.png';
import image36 from '../images/decks/image-woman/36.png';
import image37 from '../images/decks/image-woman/37.png';
import image38 from '../images/decks/image-woman/38.png';
import image39 from '../images/decks/image-woman/39.png';
import image40 from '../images/decks/image-woman/40.png';
import image41 from '../images/decks/image-woman/41.png';
import image42 from '../images/decks/image-woman/42.png';
import image43 from '../images/decks/image-woman/43.png';
import image44 from '../images/decks/image-woman/44.png';
import image45 from '../images/decks/image-woman/45.png';
import image46 from '../images/decks/image-woman/46.png';
import image47 from '../images/decks/image-woman/47.png';
import image48 from '../images/decks/image-woman/48.png';
import image49 from '../images/decks/image-woman/49.png';
import image50 from '../images/decks/image-woman/50.png';
import image51 from '../images/decks/image-woman/51.png';
import image52 from '../images/decks/image-woman/52.png';
import image53 from '../images/decks/image-woman/53.png';
import image54 from '../images/decks/image-woman/54.png';
import image55 from '../images/decks/image-woman/55.png';
import image56 from '../images/decks/image-woman/56.png';
import image57 from '../images/decks/image-woman/57.png';
import image58 from '../images/decks/image-woman/58.png';
import image59 from '../images/decks/image-woman/59.png';
import image60 from '../images/decks/image-woman/60.png';
import image61 from '../images/decks/image-woman/61.png';
import image62 from '../images/decks/image-woman/62.png';
import image63 from '../images/decks/image-woman/63.png';
import image64 from '../images/decks/image-woman/64.png';
import image65 from '../images/decks/image-woman/65.png';
import image66 from '../images/decks/image-woman/66.png';
import image67 from '../images/decks/image-woman/67.png';
import image68 from '../images/decks/image-woman/68.png';
import image69 from '../images/decks/image-woman/69.png';
import image70 from '../images/decks/image-woman/70.png';
import image71 from '../images/decks/image-woman/71.png';
import image72 from '../images/decks/image-woman/72.png';
import image73 from '../images/decks/image-woman/73.png';
import image74 from '../images/decks/image-woman/74.png';
import image75 from '../images/decks/image-woman/75.png';
import image76 from '../images/decks/image-woman/76.png';
import image77 from '../images/decks/image-woman/77.png';
import image78 from '../images/decks/image-woman/78.png';
import image79 from '../images/decks/image-woman/79.png';
import image80 from '../images/decks/image-woman/80.png';
import image81 from '../images/decks/image-woman/81.png';
import image82 from '../images/decks/image-woman/82.png';
import image83 from '../images/decks/image-woman/83.png';
import image84 from '../images/decks/image-woman/84.png';
import image85 from '../images/decks/image-woman/85.png';
import image86 from '../images/decks/image-woman/86.png';
import image87 from '../images/decks/image-woman/87.png';
import image88 from '../images/decks/image-woman/88.png';
import image89 from '../images/decks/image-woman/89.png';
import image90 from '../images/decks/image-woman/90.png';
import image91 from '../images/decks/image-woman/91.png';
import image92 from '../images/decks/image-woman/92.png';
import image93 from '../images/decks/image-woman/93.png';
import image94 from '../images/decks/image-woman/94.png';
import image95 from '../images/decks/image-woman/95.png';
import image96 from '../images/decks/image-woman/96.png';
import image97 from '../images/decks/image-woman/97.png';
import image98 from '../images/decks/image-woman/98.png';
import image99 from '../images/decks/image-woman/99.png';
import image100 from '../images/decks/image-woman/100.png';
import image101 from '../images/decks/image-woman/101.png';
import image102 from '../images/decks/image-woman/102.png';
import image103 from '../images/decks/image-woman/103.png';
import image104 from '../images/decks/image-woman/104.png';
import image105 from '../images/decks/image-woman/105.png';
import image106 from '../images/decks/image-woman/106.png';
import image107 from '../images/decks/image-woman/107.png';
import image108 from '../images/decks/image-woman/108.png';
import image109 from '../images/decks/image-woman/109.png';
import image110 from '../images/decks/image-woman/110.png';
import image111 from '../images/decks/image-woman/111.png';
import image112 from '../images/decks/image-woman/112.png';
import image113 from '../images/decks/image-woman/113.png';
import image114 from '../images/decks/image-woman/114.png';
import image115 from '../images/decks/image-woman/115.png';
import image116 from '../images/decks/image-woman/116.png';
import image117 from '../images/decks/image-woman/117.png';
import image118 from '../images/decks/image-woman/118.png';
import image119 from '../images/decks/image-woman/119.png';
import image120 from '../images/decks/image-woman/120.png';
import image121 from '../images/decks/image-woman/121.png';
import image122 from '../images/decks/image-woman/122.png';
import image123 from '../images/decks/image-woman/123.png';
import image124 from '../images/decks/image-woman/124.png';
import image125 from '../images/decks/image-woman/125.png';
import image126 from '../images/decks/image-woman/126.png';
import image127 from '../images/decks/image-woman/127.png';
import image128 from '../images/decks/image-woman/128.png';
import image129 from '../images/decks/image-woman/129.png';
import image130 from '../images/decks/image-woman/130.png';
import image131 from '../images/decks/image-woman/131.png';
import image132 from '../images/decks/image-woman/132.png';
import image133 from '../images/decks/image-woman/133.png';
import image134 from '../images/decks/image-woman/134.png';
import image135 from '../images/decks/image-woman/135.png';
import image136 from '../images/decks/image-woman/136.png';
import image137 from '../images/decks/image-woman/137.png';
import image138 from '../images/decks/image-woman/138.png';
import image139 from '../images/decks/image-woman/139.png';
import image140 from '../images/decks/image-woman/140.png';
import image141 from '../images/decks/image-woman/141.png';
import image142 from '../images/decks/image-woman/142.png';
import image143 from '../images/decks/image-woman/143.png';

export const imageWoman = {
  title: 'Образ женщины',
  cards: [
    { id: 0 + 'image-woman', url: image0 },
    { id: 1 + 'image-woman', url: image1 },
    { id: 2 + 'image-woman', url: image2 },
    { id: 3 + 'image-woman', url: image3 },
    { id: 4 + 'image-woman', url: image4 },
    { id: 5 + 'image-woman', url: image5 },
    { id: 6 + 'image-woman', url: image6 },
    { id: 7 + 'image-woman', url: image7 },
    { id: 8 + 'image-woman', url: image8 },
    { id: 9 + 'image-woman', url: image9 },
    { id: 10 + 'image-woman', url: image10 },
    { id: 11 + 'image-woman', url: image11 },
    { id: 12 + 'image-woman', url: image12 },
    { id: 13 + 'image-woman', url: image13 },
    { id: 14 + 'image-woman', url: image14 },
    { id: 15 + 'image-woman', url: image15 },
    { id: 16 + 'image-woman', url: image16 },
    { id: 17 + 'image-woman', url: image17 },
    { id: 18 + 'image-woman', url: image18 },
    { id: 19 + 'image-woman', url: image19 },
    { id: 20 + 'image-woman', url: image20 },
    { id: 21 + 'image-woman', url: image21 },
    { id: 22 + 'image-woman', url: image22 },
    { id: 23 + 'image-woman', url: image23 },
    { id: 24 + 'image-woman', url: image24 },
    { id: 25 + 'image-woman', url: image25 },
    { id: 26 + 'image-woman', url: image26 },
    { id: 27 + 'image-woman', url: image27 },
    { id: 28 + 'image-woman', url: image28 },
    { id: 29 + 'image-woman', url: image29 },
    { id: 30 + 'image-woman', url: image30 },
    { id: 31 + 'image-woman', url: image31 },
    { id: 32 + 'image-woman', url: image32 },
    { id: 33 + 'image-woman', url: image33 },
    { id: 34 + 'image-woman', url: image34 },
    { id: 35 + 'image-woman', url: image35 },
    { id: 36 + 'image-woman', url: image36 },
    { id: 37 + 'image-woman', url: image37 },
    { id: 38 + 'image-woman', url: image38 },
    { id: 39 + 'image-woman', url: image39 },
    { id: 40 + 'image-woman', url: image40 },
    { id: 41 + 'image-woman', url: image41 },
    { id: 42 + 'image-woman', url: image42 },
    { id: 43 + 'image-woman', url: image43 },
    { id: 44 + 'image-woman', url: image44 },
    { id: 45 + 'image-woman', url: image45 },
    { id: 46 + 'image-woman', url: image46 },
    { id: 47 + 'image-woman', url: image47 },
    { id: 48 + 'image-woman', url: image48 },
    { id: 49 + 'image-woman', url: image49 },
    { id: 50 + 'image-woman', url: image50 },
    { id: 51 + 'image-woman', url: image51 },
    { id: 52 + 'image-woman', url: image52 },
    { id: 53 + 'image-woman', url: image53 },
    { id: 54 + 'image-woman', url: image54 },
    { id: 55 + 'image-woman', url: image55 },
    { id: 56 + 'image-woman', url: image56 },
    { id: 57 + 'image-woman', url: image57 },
    { id: 58 + 'image-woman', url: image58 },
    { id: 59 + 'image-woman', url: image59 },
    { id: 60 + 'image-woman', url: image60 },
    { id: 61 + 'image-woman', url: image61 },
    { id: 62 + 'image-woman', url: image62 },
    { id: 63 + 'image-woman', url: image63 },
    { id: 64 + 'image-woman', url: image64 },
    { id: 65 + 'image-woman', url: image65 },
    { id: 66 + 'image-woman', url: image66 },
    { id: 67 + 'image-woman', url: image67 },
    { id: 68 + 'image-woman', url: image68 },
    { id: 69 + 'image-woman', url: image69 },
    { id: 70 + 'image-woman', url: image70 },
    { id: 71 + 'image-woman', url: image71 },
    { id: 72 + 'image-woman', url: image72 },
    { id: 73 + 'image-woman', url: image73 },
    { id: 74 + 'image-woman', url: image74 },
    { id: 75 + 'image-woman', url: image75 },
    { id: 76 + 'image-woman', url: image76 },
    { id: 77 + 'image-woman', url: image77 },
    { id: 78 + 'image-woman', url: image78 },
    { id: 79 + 'image-woman', url: image79 },
    { id: 80 + 'image-woman', url: image80 },
    { id: 81 + 'image-woman', url: image81 },
    { id: 82 + 'image-woman', url: image82 },
    { id: 83 + 'image-woman', url: image83 },
    { id: 84 + 'image-woman', url: image84 },
    { id: 85 + 'image-woman', url: image85 },
    { id: 86 + 'image-woman', url: image86 },
    { id: 87 + 'image-woman', url: image87 },
    { id: 88 + 'image-woman', url: image88 },
    { id: 89 + 'image-woman', url: image89 },
    { id: 90 + 'image-woman', url: image90 },
    { id: 91 + 'image-woman', url: image91 },
    { id: 92 + 'image-woman', url: image92 },
    { id: 93 + 'image-woman', url: image93 },
    { id: 94 + 'image-woman', url: image94 },
    { id: 95 + 'image-woman', url: image95 },
    { id: 96 + 'image-woman', url: image96 },
    { id: 97 + 'image-woman', url: image97 },
    { id: 98 + 'image-woman', url: image98 },
    { id: 99 + 'image-woman', url: image99 },
    { id: 100 + 'image-woman', url: image100 },
    { id: 101 + 'image-woman', url: image101 },
    { id: 102 + 'image-woman', url: image102 },
    { id: 103 + 'image-woman', url: image103 },
    { id: 104 + 'image-woman', url: image104 },
    { id: 105 + 'image-woman', url: image105 },
    { id: 106 + 'image-woman', url: image106 },
    { id: 107 + 'image-woman', url: image107 },
    { id: 108 + 'image-woman', url: image108 },
    { id: 109 + 'image-woman', url: image109 },
    { id: 110 + 'image-woman', url: image110 },
    { id: 111 + 'image-woman', url: image111 },
    { id: 112 + 'image-woman', url: image112 },
    { id: 113 + 'image-woman', url: image113 },
    { id: 114 + 'image-woman', url: image114 },
    { id: 115 + 'image-woman', url: image115 },
    { id: 116 + 'image-woman', url: image116 },
    { id: 117 + 'image-woman', url: image117 },
    { id: 118 + 'image-woman', url: image118 },
    { id: 119 + 'image-woman', url: image119 },
    { id: 120 + 'image-woman', url: image120 },
    { id: 121 + 'image-woman', url: image121 },
    { id: 122 + 'image-woman', url: image122 },
    { id: 123 + 'image-woman', url: image123 },
    { id: 124 + 'image-woman', url: image124 },
    { id: 125 + 'image-woman', url: image125 },
    { id: 126 + 'image-woman', url: image126 },
    { id: 127 + 'image-woman', url: image127 },
    { id: 128 + 'image-woman', url: image128 },
    { id: 129 + 'image-woman', url: image129 },
    { id: 130 + 'image-woman', url: image130 },
    { id: 131 + 'image-woman', url: image131 },
    { id: 132 + 'image-woman', url: image132 },
    { id: 133 + 'image-woman', url: image133 },
    { id: 134 + 'image-woman', url: image134 },
    { id: 135 + 'image-woman', url: image135 },
    { id: 136 + 'image-woman', url: image136 },
    { id: 137 + 'image-woman', url: image137 },
    { id: 138 + 'image-woman', url: image138 },
    { id: 139 + 'image-woman', url: image139 },
    { id: 140 + 'image-woman', url: image140 },
    { id: 141 + 'image-woman', url: image141 },
    { id: 142 + 'image-woman', url: image142 },
    { id: 143 + 'image-woman', url: image143 },
  ],
};
