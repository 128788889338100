import { FC } from 'react';
import s from './style.module.scss';
import { CardBackground } from '../card-background';
import { data } from '../../data/data';

type Props = {
  deckKeys: string[];
  setDeck: (deckKey: string) => void;
  currentDeck: string;
};

export const Decks: FC<Props> = (props) => {
  const { deckKeys, setDeck, currentDeck } = props;

  return (
    <div className={s.deck}>
      {deckKeys.map((deckKey) => (
        <div
          className={`${s.deckWrapper} ${currentDeck === deckKey ? s.picked : ''}`}
          key={deckKey}
          onClick={() => setDeck(deckKey)}
        >
          <CardBackground cardDeckName={data[deckKey].title} />
        </div>
      ))}
    </div>
  );
};
