import image0 from '../images/decks/solution/0.jpg';
import image1 from '../images/decks/solution/1.jpg';
import image2 from '../images/decks/solution/2.jpg';
import image3 from '../images/decks/solution/3.jpg';
import image4 from '../images/decks/solution/4.jpg';
import image5 from '../images/decks/solution/5.jpg';
import image6 from '../images/decks/solution/6.jpg';
import image7 from '../images/decks/solution/7.jpg';
import image8 from '../images/decks/solution/8.jpg';
import image9 from '../images/decks/solution/9.jpg';
import image10 from '../images/decks/solution/10.jpg';
import image11 from '../images/decks/solution/11.jpg';
import image12 from '../images/decks/solution/12.jpg';
import image13 from '../images/decks/solution/13.jpg';
import image14 from '../images/decks/solution/14.jpg';
import image15 from '../images/decks/solution/15.jpg';
import image16 from '../images/decks/solution/16.jpg';
import image17 from '../images/decks/solution/17.jpg';
import image18 from '../images/decks/solution/18.jpg';
import image19 from '../images/decks/solution/19.jpg';
import image20 from '../images/decks/solution/20.jpg';
import image21 from '../images/decks/solution/21.jpg';
import image22 from '../images/decks/solution/22.jpg';
import image23 from '../images/decks/solution/23.jpg';
import image24 from '../images/decks/solution/24.jpg';
import image25 from '../images/decks/solution/25.jpg';
import image26 from '../images/decks/solution/26.jpg';
import image27 from '../images/decks/solution/27.jpg';
import image28 from '../images/decks/solution/28.jpg';
import image29 from '../images/decks/solution/29.jpg';
import image30 from '../images/decks/solution/30.jpg';
import image31 from '../images/decks/solution/31.jpg';
import image32 from '../images/decks/solution/32.jpg';
import image33 from '../images/decks/solution/33.jpg';
import image34 from '../images/decks/solution/34.jpg';
import image35 from '../images/decks/solution/35.jpg';
import image36 from '../images/decks/solution/36.jpg';
import image37 from '../images/decks/solution/37.jpg';
import image38 from '../images/decks/solution/38.jpg';
import image39 from '../images/decks/solution/39.jpg';
import image40 from '../images/decks/solution/40.jpg';
import image41 from '../images/decks/solution/41.jpg';
import image42 from '../images/decks/solution/42.jpg';
import image43 from '../images/decks/solution/43.jpg';
import image44 from '../images/decks/solution/44.jpg';
import image45 from '../images/decks/solution/45.jpg';
import image46 from '../images/decks/solution/46.jpg';
import image47 from '../images/decks/solution/47.jpg';
import image48 from '../images/decks/solution/48.jpg';
import image49 from '../images/decks/solution/49.jpg';
import image50 from '../images/decks/solution/50.jpg';
import image51 from '../images/decks/solution/51.jpg';
import image52 from '../images/decks/solution/52.jpg';
import image53 from '../images/decks/solution/53.jpg';
import image54 from '../images/decks/solution/54.jpg';
import image55 from '../images/decks/solution/55.jpg';
import image56 from '../images/decks/solution/56.jpg';
import image57 from '../images/decks/solution/57.jpg';
import image58 from '../images/decks/solution/58.jpg';
import image59 from '../images/decks/solution/59.jpg';
import image60 from '../images/decks/solution/60.jpg';
import image61 from '../images/decks/solution/61.jpg';
import image62 from '../images/decks/solution/62.jpg';
import image63 from '../images/decks/solution/63.jpg';
import image64 from '../images/decks/solution/64.jpg';
import image65 from '../images/decks/solution/65.jpg';
import image66 from '../images/decks/solution/66.jpg';
import image67 from '../images/decks/solution/67.jpg';
import image68 from '../images/decks/solution/68.jpg';
import image69 from '../images/decks/solution/69.jpg';
import image70 from '../images/decks/solution/70.jpg';
import image71 from '../images/decks/solution/71.jpg';
import image72 from '../images/decks/solution/72.jpg';
import image73 from '../images/decks/solution/73.jpg';
import image74 from '../images/decks/solution/74.jpg';
import image75 from '../images/decks/solution/75.jpg';
import image76 from '../images/decks/solution/76.jpg';
import image77 from '../images/decks/solution/77.jpg';
import image78 from '../images/decks/solution/78.jpg';
import image79 from '../images/decks/solution/79.jpg';
import image80 from '../images/decks/solution/80.jpg';
import image81 from '../images/decks/solution/81.jpg';
import image82 from '../images/decks/solution/82.jpg';
import image83 from '../images/decks/solution/83.jpg';
import image84 from '../images/decks/solution/84.jpg';
import image85 from '../images/decks/solution/85.jpg';
import image86 from '../images/decks/solution/86.jpg';
import image87 from '../images/decks/solution/87.jpg';
import image88 from '../images/decks/solution/88.jpg';
import image89 from '../images/decks/solution/89.jpg';
import image90 from '../images/decks/solution/90.jpg';
import image91 from '../images/decks/solution/91.jpg';
import image92 from '../images/decks/solution/92.jpg';
import image93 from '../images/decks/solution/93.jpg';
import image94 from '../images/decks/solution/94.jpg';
import image95 from '../images/decks/solution/95.jpg';
import image96 from '../images/decks/solution/96.jpg';
import image97 from '../images/decks/solution/97.jpg';
import image98 from '../images/decks/solution/98.jpg';
import image99 from '../images/decks/solution/99.jpg';

export const solution = {
  title: 'Решение',
  cards: [
    { id: 0 + 'solution', url: image0 },
    { id: 1 + 'solution', url: image1 },
    { id: 2 + 'solution', url: image2 },
    { id: 3 + 'solution', url: image3 },
    { id: 4 + 'solution', url: image4 },
    { id: 5 + 'solution', url: image5 },
    { id: 6 + 'solution', url: image6 },
    { id: 7 + 'solution', url: image7 },
    { id: 8 + 'solution', url: image8 },
    { id: 9 + 'solution', url: image9 },
    { id: 10 + 'solution', url: image10 },
    { id: 11 + 'solution', url: image11 },
    { id: 12 + 'solution', url: image12 },
    { id: 13 + 'solution', url: image13 },
    { id: 14 + 'solution', url: image14 },
    { id: 15 + 'solution', url: image15 },
    { id: 16 + 'solution', url: image16 },
    { id: 17 + 'solution', url: image17 },
    { id: 18 + 'solution', url: image18 },
    { id: 19 + 'solution', url: image19 },
    { id: 20 + 'solution', url: image20 },
    { id: 21 + 'solution', url: image21 },
    { id: 22 + 'solution', url: image22 },
    { id: 23 + 'solution', url: image23 },
    { id: 24 + 'solution', url: image24 },
    { id: 25 + 'solution', url: image25 },
    { id: 26 + 'solution', url: image26 },
    { id: 27 + 'solution', url: image27 },
    { id: 28 + 'solution', url: image28 },
    { id: 29 + 'solution', url: image29 },
    { id: 30 + 'solution', url: image30 },
    { id: 31 + 'solution', url: image31 },
    { id: 32 + 'solution', url: image32 },
    { id: 33 + 'solution', url: image33 },
    { id: 34 + 'solution', url: image34 },
    { id: 35 + 'solution', url: image35 },
    { id: 36 + 'solution', url: image36 },
    { id: 37 + 'solution', url: image37 },
    { id: 38 + 'solution', url: image38 },
    { id: 39 + 'solution', url: image39 },
    { id: 40 + 'solution', url: image40 },
    { id: 41 + 'solution', url: image41 },
    { id: 42 + 'solution', url: image42 },
    { id: 43 + 'solution', url: image43 },
    { id: 44 + 'solution', url: image44 },
    { id: 45 + 'solution', url: image45 },
    { id: 46 + 'solution', url: image46 },
    { id: 47 + 'solution', url: image47 },
    { id: 48 + 'solution', url: image48 },
    { id: 49 + 'solution', url: image49 },
    { id: 50 + 'solution', url: image50 },
    { id: 51 + 'solution', url: image51 },
    { id: 52 + 'solution', url: image52 },
    { id: 53 + 'solution', url: image53 },
    { id: 54 + 'solution', url: image54 },
    { id: 55 + 'solution', url: image55 },
    { id: 56 + 'solution', url: image56 },
    { id: 57 + 'solution', url: image57 },
    { id: 58 + 'solution', url: image58 },
    { id: 59 + 'solution', url: image59 },
    { id: 60 + 'solution', url: image60 },
    { id: 61 + 'solution', url: image61 },
    { id: 62 + 'solution', url: image62 },
    { id: 63 + 'solution', url: image63 },
    { id: 64 + 'solution', url: image64 },
    { id: 65 + 'solution', url: image65 },
    { id: 66 + 'solution', url: image66 },
    { id: 67 + 'solution', url: image67 },
    { id: 68 + 'solution', url: image68 },
    { id: 69 + 'solution', url: image69 },
    { id: 70 + 'solution', url: image70 },
    { id: 71 + 'solution', url: image71 },
    { id: 72 + 'solution', url: image72 },
    { id: 73 + 'solution', url: image73 },
    { id: 74 + 'solution', url: image74 },
    { id: 75 + 'solution', url: image75 },
    { id: 76 + 'solution', url: image76 },
    { id: 77 + 'solution', url: image77 },
    { id: 78 + 'solution', url: image78 },
    { id: 79 + 'solution', url: image79 },
    { id: 80 + 'solution', url: image80 },
    { id: 81 + 'solution', url: image81 },
    { id: 82 + 'solution', url: image82 },
    { id: 83 + 'solution', url: image83 },
    { id: 84 + 'solution', url: image84 },
    { id: 85 + 'solution', url: image85 },
    { id: 86 + 'solution', url: image86 },
    { id: 87 + 'solution', url: image87 },
    { id: 88 + 'solution', url: image88 },
    { id: 89 + 'solution', url: image89 },
    { id: 90 + 'solution', url: image90 },
    { id: 91 + 'solution', url: image91 },
    { id: 92 + 'solution', url: image92 },
    { id: 93 + 'solution', url: image93 },
    { id: 94 + 'solution', url: image94 },
    { id: 95 + 'solution', url: image95 },
    { id: 96 + 'solution', url: image96 },
    { id: 97 + 'solution', url: image97 },
    { id: 98 + 'solution', url: image98 },
    { id: 99 + 'solution', url: image99 },
  ],
};
