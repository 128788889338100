import s from './style.module.scss';
import { Decks } from '../../decks';
import { data } from '../../../data/data';
import { Layout } from 'antd';
import { FC } from 'react';

type Props = {
  collapsed: boolean;
  setDeck: (deck: string) => void;
  deck: string;
};

export const Sider: FC<Props> = (props) => {
  const { setDeck, deck, collapsed } = props;

  return (
    <Layout.Sider width="170" collapsedWidth={0} className={s.sider} trigger={null} collapsible collapsed={collapsed}>
      <Decks deckKeys={Object.keys(data)} setDeck={setDeck} currentDeck={deck} />
    </Layout.Sider>
  );
};
