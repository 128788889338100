import image0 from '../images/decks/resources/0.jpg';
import image1 from '../images/decks/resources/1.jpg';
import image2 from '../images/decks/resources/2.jpg';
import image3 from '../images/decks/resources/3.jpg';
import image4 from '../images/decks/resources/4.jpg';
import image5 from '../images/decks/resources/5.jpg';
import image6 from '../images/decks/resources/6.jpg';
import image7 from '../images/decks/resources/7.jpg';
import image8 from '../images/decks/resources/8.jpg';
import image9 from '../images/decks/resources/9.jpg';
import image10 from '../images/decks/resources/10.jpg';
import image11 from '../images/decks/resources/11.jpg';
import image12 from '../images/decks/resources/12.jpg';
import image13 from '../images/decks/resources/13.jpg';
import image14 from '../images/decks/resources/14.jpg';
import image15 from '../images/decks/resources/15.jpg';
import image16 from '../images/decks/resources/16.jpg';
import image17 from '../images/decks/resources/17.jpg';
import image18 from '../images/decks/resources/18.jpg';
import image19 from '../images/decks/resources/19.jpg';
import image20 from '../images/decks/resources/20.jpg';
import image21 from '../images/decks/resources/21.jpg';
import image22 from '../images/decks/resources/22.jpg';
import image23 from '../images/decks/resources/23.jpg';
import image24 from '../images/decks/resources/24.jpg';
import image25 from '../images/decks/resources/25.jpg';
import image26 from '../images/decks/resources/26.jpg';
import image27 from '../images/decks/resources/27.jpg';
import image28 from '../images/decks/resources/28.jpg';
import image29 from '../images/decks/resources/29.jpg';
import image30 from '../images/decks/resources/30.jpg';
import image31 from '../images/decks/resources/31.jpg';
import image32 from '../images/decks/resources/32.jpg';
import image33 from '../images/decks/resources/33.jpg';
import image34 from '../images/decks/resources/34.jpg';
import image35 from '../images/decks/resources/35.jpg';
import image36 from '../images/decks/resources/36.jpg';
import image37 from '../images/decks/resources/37.jpg';
import image38 from '../images/decks/resources/38.jpg';
import image39 from '../images/decks/resources/39.jpg';
import image40 from '../images/decks/resources/40.jpg';
import image41 from '../images/decks/resources/41.jpg';
import image42 from '../images/decks/resources/42.jpg';
import image43 from '../images/decks/resources/43.jpg';
import image44 from '../images/decks/resources/44.jpg';
import image45 from '../images/decks/resources/45.jpg';
import image46 from '../images/decks/resources/46.jpg';
import image47 from '../images/decks/resources/47.jpg';
import image48 from '../images/decks/resources/48.jpg';
import image49 from '../images/decks/resources/49.jpg';
import image50 from '../images/decks/resources/50.jpg';
import image51 from '../images/decks/resources/51.jpg';
import image52 from '../images/decks/resources/52.jpg';
import image53 from '../images/decks/resources/53.jpg';
import image54 from '../images/decks/resources/54.jpg';
import image55 from '../images/decks/resources/55.jpg';
import image56 from '../images/decks/resources/56.jpg';
import image57 from '../images/decks/resources/57.jpg';
import image58 from '../images/decks/resources/58.jpg';
import image59 from '../images/decks/resources/59.jpg';
import image60 from '../images/decks/resources/60.jpg';
import image61 from '../images/decks/resources/61.jpg';
import image62 from '../images/decks/resources/62.jpg';
import image63 from '../images/decks/resources/63.jpg';
import image64 from '../images/decks/resources/64.jpg';
import image65 from '../images/decks/resources/65.jpg';
import image66 from '../images/decks/resources/66.jpg';
import image67 from '../images/decks/resources/67.jpg';
import image68 from '../images/decks/resources/68.jpg';
import image69 from '../images/decks/resources/69.jpg';
import image70 from '../images/decks/resources/70.jpg';
import image71 from '../images/decks/resources/71.jpg';
import image72 from '../images/decks/resources/72.jpg';
import image73 from '../images/decks/resources/73.jpg';
import image74 from '../images/decks/resources/74.jpg';
import image75 from '../images/decks/resources/75.jpg';
import image76 from '../images/decks/resources/76.jpg';
import image77 from '../images/decks/resources/77.jpg';
import image78 from '../images/decks/resources/78.jpg';
import image79 from '../images/decks/resources/79.jpg';
import image80 from '../images/decks/resources/80.jpg';
import image81 from '../images/decks/resources/81.jpg';
import image82 from '../images/decks/resources/82.jpg';
import image83 from '../images/decks/resources/83.jpg';
import image84 from '../images/decks/resources/84.jpg';
import image85 from '../images/decks/resources/85.jpg';
import image86 from '../images/decks/resources/86.jpg';
import image87 from '../images/decks/resources/87.jpg';
import image88 from '../images/decks/resources/88.jpg';
import image89 from '../images/decks/resources/89.jpg';
import image90 from '../images/decks/resources/90.jpg';
import image91 from '../images/decks/resources/91.jpg';
import image92 from '../images/decks/resources/92.jpg';
import image93 from '../images/decks/resources/93.jpg';
import image94 from '../images/decks/resources/94.jpg';
import image95 from '../images/decks/resources/95.jpg';
import image96 from '../images/decks/resources/96.jpg';
import image97 from '../images/decks/resources/97.jpg';
import image98 from '../images/decks/resources/98.jpg';
import image99 from '../images/decks/resources/99.jpg';
import image100 from '../images/decks/resources/100.jpg';
import image101 from '../images/decks/resources/101.jpg';
import image102 from '../images/decks/resources/102.jpg';
import image103 from '../images/decks/resources/103.jpg';
import image104 from '../images/decks/resources/104.jpg';
import image105 from '../images/decks/resources/105.jpg';
import image106 from '../images/decks/resources/106.jpg';
import image107 from '../images/decks/resources/107.jpg';

export const resources = {
  title: 'Ресурс',
  cards: [
    { id: 0 + 'resources', url: image0 },
    { id: 1 + 'resources', url: image1 },
    { id: 2 + 'resources', url: image2 },
    { id: 3 + 'resources', url: image3 },
    { id: 4 + 'resources', url: image4 },
    { id: 5 + 'resources', url: image5 },
    { id: 6 + 'resources', url: image6 },
    { id: 7 + 'resources', url: image7 },
    { id: 8 + 'resources', url: image8 },
    { id: 9 + 'resources', url: image9 },
    { id: 10 + 'resources', url: image10 },
    { id: 11 + 'resources', url: image11 },
    { id: 12 + 'resources', url: image12 },
    { id: 13 + 'resources', url: image13 },
    { id: 14 + 'resources', url: image14 },
    { id: 15 + 'resources', url: image15 },
    { id: 16 + 'resources', url: image16 },
    { id: 17 + 'resources', url: image17 },
    { id: 18 + 'resources', url: image18 },
    { id: 19 + 'resources', url: image19 },
    { id: 20 + 'resources', url: image20 },
    { id: 21 + 'resources', url: image21 },
    { id: 22 + 'resources', url: image22 },
    { id: 23 + 'resources', url: image23 },
    { id: 24 + 'resources', url: image24 },
    { id: 25 + 'resources', url: image25 },
    { id: 26 + 'resources', url: image26 },
    { id: 27 + 'resources', url: image27 },
    { id: 28 + 'resources', url: image28 },
    { id: 29 + 'resources', url: image29 },
    { id: 30 + 'resources', url: image30 },
    { id: 31 + 'resources', url: image31 },
    { id: 32 + 'resources', url: image32 },
    { id: 33 + 'resources', url: image33 },
    { id: 34 + 'resources', url: image34 },
    { id: 35 + 'resources', url: image35 },
    { id: 36 + 'resources', url: image36 },
    { id: 37 + 'resources', url: image37 },
    { id: 38 + 'resources', url: image38 },
    { id: 39 + 'resources', url: image39 },
    { id: 40 + 'resources', url: image40 },
    { id: 41 + 'resources', url: image41 },
    { id: 42 + 'resources', url: image42 },
    { id: 43 + 'resources', url: image43 },
    { id: 44 + 'resources', url: image44 },
    { id: 45 + 'resources', url: image45 },
    { id: 46 + 'resources', url: image46 },
    { id: 47 + 'resources', url: image47 },
    { id: 48 + 'resources', url: image48 },
    { id: 49 + 'resources', url: image49 },
    { id: 50 + 'resources', url: image50 },
    { id: 51 + 'resources', url: image51 },
    { id: 52 + 'resources', url: image52 },
    { id: 53 + 'resources', url: image53 },
    { id: 54 + 'resources', url: image54 },
    { id: 55 + 'resources', url: image55 },
    { id: 56 + 'resources', url: image56 },
    { id: 57 + 'resources', url: image57 },
    { id: 58 + 'resources', url: image58 },
    { id: 59 + 'resources', url: image59 },
    { id: 60 + 'resources', url: image60 },
    { id: 61 + 'resources', url: image61 },
    { id: 62 + 'resources', url: image62 },
    { id: 63 + 'resources', url: image63 },
    { id: 64 + 'resources', url: image64 },
    { id: 65 + 'resources', url: image65 },
    { id: 66 + 'resources', url: image66 },
    { id: 67 + 'resources', url: image67 },
    { id: 68 + 'resources', url: image68 },
    { id: 69 + 'resources', url: image69 },
    { id: 70 + 'resources', url: image70 },
    { id: 71 + 'resources', url: image71 },
    { id: 72 + 'resources', url: image72 },
    { id: 73 + 'resources', url: image73 },
    { id: 74 + 'resources', url: image74 },
    { id: 75 + 'resources', url: image75 },
    { id: 76 + 'resources', url: image76 },
    { id: 77 + 'resources', url: image77 },
    { id: 78 + 'resources', url: image78 },
    { id: 79 + 'resources', url: image79 },
    { id: 80 + 'resources', url: image80 },
    { id: 81 + 'resources', url: image81 },
    { id: 82 + 'resources', url: image82 },
    { id: 83 + 'resources', url: image83 },
    { id: 84 + 'resources', url: image84 },
    { id: 85 + 'resources', url: image85 },
    { id: 86 + 'resources', url: image86 },
    { id: 87 + 'resources', url: image87 },
    { id: 88 + 'resources', url: image88 },
    { id: 89 + 'resources', url: image89 },
    { id: 90 + 'resources', url: image90 },
    { id: 91 + 'resources', url: image91 },
    { id: 92 + 'resources', url: image92 },
    { id: 93 + 'resources', url: image93 },
    { id: 94 + 'resources', url: image94 },
    { id: 95 + 'resources', url: image95 },
    { id: 96 + 'resources', url: image96 },
    { id: 97 + 'resources', url: image97 },
    { id: 98 + 'resources', url: image98 },
    { id: 99 + 'resources', url: image99 },
    { id: 100 + 'resources', url: image100 },
    { id: 101 + 'resources', url: image101 },
    { id: 102 + 'resources', url: image102 },
    { id: 103 + 'resources', url: image103 },
    { id: 104 + 'resources', url: image104 },
    { id: 105 + 'resources', url: image105 },
    { id: 106 + 'resources', url: image106 },
    { id: 107 + 'resources', url: image107 },
  ],
};
