import image0 from '../images/decks/source/0.jpg';
import image1 from '../images/decks/source/1.jpg';
import image2 from '../images/decks/source/2.jpg';
import image3 from '../images/decks/source/3.jpg';
import image4 from '../images/decks/source/4.jpg';
import image5 from '../images/decks/source/5.jpg';
import image6 from '../images/decks/source/6.jpg';
import image7 from '../images/decks/source/7.jpg';
import image8 from '../images/decks/source/8.jpg';
import image9 from '../images/decks/source/9.jpg';
import image10 from '../images/decks/source/10.jpg';
import image11 from '../images/decks/source/11.jpg';
import image12 from '../images/decks/source/12.jpg';
import image13 from '../images/decks/source/13.jpg';
import image14 from '../images/decks/source/14.jpg';
import image15 from '../images/decks/source/15.jpg';
import image16 from '../images/decks/source/16.jpg';
import image17 from '../images/decks/source/17.jpg';
import image18 from '../images/decks/source/18.jpg';
import image19 from '../images/decks/source/19.jpg';
import image20 from '../images/decks/source/20.jpg';
import image21 from '../images/decks/source/21.jpg';
import image22 from '../images/decks/source/22.jpg';
import image23 from '../images/decks/source/23.jpg';
import image24 from '../images/decks/source/24.jpg';
import image25 from '../images/decks/source/25.jpg';
import image26 from '../images/decks/source/26.jpg';
import image27 from '../images/decks/source/27.jpg';
import image28 from '../images/decks/source/28.jpg';
import image29 from '../images/decks/source/29.jpg';
import image30 from '../images/decks/source/30.jpg';
import image31 from '../images/decks/source/31.jpg';
import image32 from '../images/decks/source/32.jpg';
import image33 from '../images/decks/source/33.jpg';
import image34 from '../images/decks/source/34.jpg';
import image35 from '../images/decks/source/35.jpg';
import image36 from '../images/decks/source/36.jpg';
import image37 from '../images/decks/source/37.jpg';
import image38 from '../images/decks/source/38.jpg';
import image39 from '../images/decks/source/39.jpg';
import image40 from '../images/decks/source/40.jpg';
import image41 from '../images/decks/source/41.jpg';
import image42 from '../images/decks/source/42.jpg';
import image43 from '../images/decks/source/43.jpg';
import image44 from '../images/decks/source/44.jpg';
import image45 from '../images/decks/source/45.jpg';
import image46 from '../images/decks/source/46.jpg';
import image47 from '../images/decks/source/47.jpg';
import image48 from '../images/decks/source/48.jpg';
import image49 from '../images/decks/source/49.jpg';
import image50 from '../images/decks/source/50.jpg';
import image51 from '../images/decks/source/51.jpg';
import image52 from '../images/decks/source/52.jpg';
import image53 from '../images/decks/source/53.jpg';
import image54 from '../images/decks/source/54.jpg';
import image55 from '../images/decks/source/55.jpg';
import image56 from '../images/decks/source/56.jpg';
import image57 from '../images/decks/source/57.jpg';
import image58 from '../images/decks/source/58.jpg';
import image59 from '../images/decks/source/59.jpg';
import image60 from '../images/decks/source/60.jpg';
import image61 from '../images/decks/source/61.jpg';
import image62 from '../images/decks/source/62.jpg';
import image63 from '../images/decks/source/63.jpg';
import image64 from '../images/decks/source/64.jpg';
import image65 from '../images/decks/source/65.jpg';
import image66 from '../images/decks/source/66.jpg';
import image67 from '../images/decks/source/67.jpg';
import image68 from '../images/decks/source/68.jpg';
import image69 from '../images/decks/source/69.jpg';
import image70 from '../images/decks/source/70.jpg';
import image71 from '../images/decks/source/71.jpg';
import image72 from '../images/decks/source/72.jpg';
import image73 from '../images/decks/source/73.jpg';
import image74 from '../images/decks/source/74.jpg';
import image75 from '../images/decks/source/75.jpg';
import image76 from '../images/decks/source/76.jpg';
import image77 from '../images/decks/source/77.jpg';
import image78 from '../images/decks/source/78.jpg';
import image79 from '../images/decks/source/79.jpg';
import image80 from '../images/decks/source/80.jpg';
import image81 from '../images/decks/source/81.jpg';
import image82 from '../images/decks/source/82.jpg';
import image83 from '../images/decks/source/83.jpg';
import image84 from '../images/decks/source/84.jpg';
import image85 from '../images/decks/source/85.jpg';
import image86 from '../images/decks/source/86.jpg';
import image87 from '../images/decks/source/87.jpg';
import image88 from '../images/decks/source/88.jpg';
import image89 from '../images/decks/source/89.jpg';
import image90 from '../images/decks/source/90.jpg';
import image91 from '../images/decks/source/91.jpg';
import image92 from '../images/decks/source/92.jpg';
import image93 from '../images/decks/source/93.jpg';
import image94 from '../images/decks/source/94.jpg';
import image95 from '../images/decks/source/95.jpg';
import image96 from '../images/decks/source/96.jpg';
import image97 from '../images/decks/source/97.jpg';
import image98 from '../images/decks/source/98.jpg';
import image99 from '../images/decks/source/99.jpg';
import image100 from '../images/decks/source/100.jpg';
import image101 from '../images/decks/source/101.jpg';
import image102 from '../images/decks/source/102.jpg';
import image103 from '../images/decks/source/103.jpg';
import image104 from '../images/decks/source/104.jpg';
import image105 from '../images/decks/source/105.jpg';
import image106 from '../images/decks/source/106.jpg';
import image107 from '../images/decks/source/107.jpg';
import image108 from '../images/decks/source/108.jpg';
import image109 from '../images/decks/source/109.jpg';
import image110 from '../images/decks/source/110.jpg';
import image111 from '../images/decks/source/111.jpg';
import image112 from '../images/decks/source/112.jpg';
import image113 from '../images/decks/source/113.jpg';
import image114 from '../images/decks/source/114.jpg';
import image115 from '../images/decks/source/115.jpg';
import image116 from '../images/decks/source/116.jpg';
import image117 from '../images/decks/source/117.jpg';
import image118 from '../images/decks/source/118.jpg';
import image119 from '../images/decks/source/119.jpg';
import image120 from '../images/decks/source/120.jpg';
import image121 from '../images/decks/source/121.jpg';
import image122 from '../images/decks/source/122.jpg';
import image123 from '../images/decks/source/123.jpg';
import image124 from '../images/decks/source/124.jpg';
import image125 from '../images/decks/source/125.jpg';
import image126 from '../images/decks/source/126.jpg';
import image127 from '../images/decks/source/127.jpg';
import image128 from '../images/decks/source/128.jpg';
import image129 from '../images/decks/source/129.jpg';
import image130 from '../images/decks/source/130.jpg';

export const source = {
  title: 'Источник',
  cards: [
    { id: 0 + 'source', url: image0 },
    { id: 1 + 'source', url: image1 },
    { id: 2 + 'source', url: image2 },
    { id: 3 + 'source', url: image3 },
    { id: 4 + 'source', url: image4 },
    { id: 5 + 'source', url: image5 },
    { id: 6 + 'source', url: image6 },
    { id: 7 + 'source', url: image7 },
    { id: 8 + 'source', url: image8 },
    { id: 9 + 'source', url: image9 },
    { id: 10 + 'source', url: image10 },
    { id: 11 + 'source', url: image11 },
    { id: 12 + 'source', url: image12 },
    { id: 13 + 'source', url: image13 },
    { id: 14 + 'source', url: image14 },
    { id: 15 + 'source', url: image15 },
    { id: 16 + 'source', url: image16 },
    { id: 17 + 'source', url: image17 },
    { id: 18 + 'source', url: image18 },
    { id: 19 + 'source', url: image19 },
    { id: 20 + 'source', url: image20 },
    { id: 21 + 'source', url: image21 },
    { id: 22 + 'source', url: image22 },
    { id: 23 + 'source', url: image23 },
    { id: 24 + 'source', url: image24 },
    { id: 25 + 'source', url: image25 },
    { id: 26 + 'source', url: image26 },
    { id: 27 + 'source', url: image27 },
    { id: 28 + 'source', url: image28 },
    { id: 29 + 'source', url: image29 },
    { id: 30 + 'source', url: image30 },
    { id: 31 + 'source', url: image31 },
    { id: 32 + 'source', url: image32 },
    { id: 33 + 'source', url: image33 },
    { id: 34 + 'source', url: image34 },
    { id: 35 + 'source', url: image35 },
    { id: 36 + 'source', url: image36 },
    { id: 37 + 'source', url: image37 },
    { id: 38 + 'source', url: image38 },
    { id: 39 + 'source', url: image39 },
    { id: 40 + 'source', url: image40 },
    { id: 41 + 'source', url: image41 },
    { id: 42 + 'source', url: image42 },
    { id: 43 + 'source', url: image43 },
    { id: 44 + 'source', url: image44 },
    { id: 45 + 'source', url: image45 },
    { id: 46 + 'source', url: image46 },
    { id: 47 + 'source', url: image47 },
    { id: 48 + 'source', url: image48 },
    { id: 49 + 'source', url: image49 },
    { id: 50 + 'source', url: image50 },
    { id: 51 + 'source', url: image51 },
    { id: 52 + 'source', url: image52 },
    { id: 53 + 'source', url: image53 },
    { id: 54 + 'source', url: image54 },
    { id: 55 + 'source', url: image55 },
    { id: 56 + 'source', url: image56 },
    { id: 57 + 'source', url: image57 },
    { id: 58 + 'source', url: image58 },
    { id: 59 + 'source', url: image59 },
    { id: 60 + 'source', url: image60 },
    { id: 61 + 'source', url: image61 },
    { id: 62 + 'source', url: image62 },
    { id: 63 + 'source', url: image63 },
    { id: 64 + 'source', url: image64 },
    { id: 65 + 'source', url: image65 },
    { id: 66 + 'source', url: image66 },
    { id: 67 + 'source', url: image67 },
    { id: 68 + 'source', url: image68 },
    { id: 69 + 'source', url: image69 },
    { id: 70 + 'source', url: image70 },
    { id: 71 + 'source', url: image71 },
    { id: 72 + 'source', url: image72 },
    { id: 73 + 'source', url: image73 },
    { id: 74 + 'source', url: image74 },
    { id: 75 + 'source', url: image75 },
    { id: 76 + 'source', url: image76 },
    { id: 77 + 'source', url: image77 },
    { id: 78 + 'source', url: image78 },
    { id: 79 + 'source', url: image79 },
    { id: 80 + 'source', url: image80 },
    { id: 81 + 'source', url: image81 },
    { id: 82 + 'source', url: image82 },
    { id: 83 + 'source', url: image83 },
    { id: 84 + 'source', url: image84 },
    { id: 85 + 'source', url: image85 },
    { id: 86 + 'source', url: image86 },
    { id: 87 + 'source', url: image87 },
    { id: 88 + 'source', url: image88 },
    { id: 89 + 'source', url: image89 },
    { id: 90 + 'source', url: image90 },
    { id: 91 + 'source', url: image91 },
    { id: 92 + 'source', url: image92 },
    { id: 93 + 'source', url: image93 },
    { id: 94 + 'source', url: image94 },
    { id: 95 + 'source', url: image95 },
    { id: 96 + 'source', url: image96 },
    { id: 97 + 'source', url: image97 },
    { id: 98 + 'source', url: image98 },
    { id: 99 + 'source', url: image99 },
    { id: 100 + 'source', url: image100 },
    { id: 101 + 'source', url: image101 },
    { id: 102 + 'source', url: image102 },
    { id: 103 + 'source', url: image103 },
    { id: 104 + 'source', url: image104 },
    { id: 105 + 'source', url: image105 },
    { id: 106 + 'source', url: image106 },
    { id: 107 + 'source', url: image107 },
    { id: 108 + 'source', url: image108 },
    { id: 109 + 'source', url: image109 },
    { id: 110 + 'source', url: image110 },
    { id: 111 + 'source', url: image111 },
    { id: 112 + 'source', url: image112 },
    { id: 113 + 'source', url: image113 },
    { id: 114 + 'source', url: image114 },
    { id: 115 + 'source', url: image115 },
    { id: 116 + 'source', url: image116 },
    { id: 117 + 'source', url: image117 },
    { id: 118 + 'source', url: image118 },
    { id: 119 + 'source', url: image119 },
    { id: 120 + 'source', url: image120 },
    { id: 121 + 'source', url: image121 },
    { id: 122 + 'source', url: image122 },
    { id: 123 + 'source', url: image123 },
    { id: 124 + 'source', url: image124 },
    { id: 125 + 'source', url: image125 },
    { id: 126 + 'source', url: image126 },
    { id: 127 + 'source', url: image127 },
    { id: 128 + 'source', url: image128 },
    { id: 129 + 'source', url: image129 },
    { id: 130 + 'source', url: image130 },
  ],
};
