import { FC } from 'react';
import s from './style.module.scss';
import loyalImage from '../../images/main-image.png';
import { TypeCard } from '../../types';
import { Content } from 'antd/es/layout/layout';
import { CardOnBoard } from '../cards/card-on-board';

type Props<T = TypeCard> = {
  cards: T[];
  openCards: boolean;
  onRemove: (card: T) => void;
};

export const Board: FC<Props> = (props) => {
  const { cards, openCards, onRemove } = props;

  return (
    <Content className={s.content}>
      <img className={s.imageBackground} src={loyalImage} alt="Клуб лояльности к Себе" />
      {cards.map((card, idx) => {
        const openCard = card.openCard !== undefined ? card.openCard : openCards;
        return (
          <CardOnBoard
            key={card.url + card.id}
            card={card}
            openCard={openCard}
            onRemove={onRemove}
            cardNumber={idx + 1}
          />
        );
      })}
    </Content>
  );
};
